import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, delay, map, take } from 'rxjs/operators';
import { IMemberModel } from '@shared/state/interface';

@Injectable({
    providedIn: 'root',
})
export class MembersService/*  implements Resolve<Models.IMember> */ {
    /* https://stackoverflow.com/questions/39777220/cannot-read-property-type-of-undefined-ngrx */

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) { }

    public getUserData(): Observable<APIv3.AuthGetCurrentMember.Responses.$200> {
        return this.httpClient.get<APIv3.AuthGetCurrentMember.Responses.$200>(`${this.config.api.base}/auth/member/currentUser`)
            .pipe(
                map((member) =>
                    // TODO - To remove after update on database (more in _currentUserConverter's comment)
                    this._currentUserConverter(member)
                ),
                delay(1000),
                catchError((ex) => throwError(ex))
            );
    }

    // TODO - Because in db MobilePhone can be in string format or (prefix + string) format we have to find user country,
    //  countryCodeId and Prefix before we upgrade each partner's DBs. After this we remove this part of code.
    private _currentUserConverter(member: IMemberModel): IMemberModel {
        if (member.MobilePhone.match(/\+/i)) {
            const country = this.config.countries.filter(c => member.MobilePhone.includes(c.PhonePrefix))[0];
            if (country) {
                member.MobilePhonePrefix = country.PhonePrefix;
                member.MobilePhone = member.MobilePhone.split(country.PhonePrefix)[1];
                member.MobilePhoneCountryId = country.Id;
            }
        }

        /* Making sure that no other bugs pop out due to lack of MemberId prop */
        return {
            ...member,
            MemberId: member.UserId,
        };
    }

    public checkMemberUniqueCode(memberCode: string): Observable<IMemberModel> {
        return this.httpClient.get<IMemberModel>(`${this.config.api.base}/members/uniqueCode/${window.encodeURIComponent(memberCode)}`);
    }

    public getMembers(props: APICommon.IMembersGetPaginatedList = {}): Observable<IMemberModel> {
        return this.httpClient.get<IMemberModel>(`${this.config.api.base}/members${Utils.HTTP.object2string(props)}`);
    }

    public validateUserNewProfileData(member: IMemberModel): Observable<boolean> {
        /* When updating user profile details, verify its email and phone no */
        return forkJoin(
            this.validateMemberByProperty(member.MobilePhone, member.MobilePhoneCountryId, OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN, member.UserId),
            this.validateMemberByProperty(member.Email, member.MobilePhoneCountryId, OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN, member.UserId),
        )
            .pipe(
                map(([byPhone, byEmail]) => !(byPhone || byEmail))
            );
    }

    public updateUser(memberModel: IMemberModel): Observable<APIv3.MembersUpdateMember.Responses.$200> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/my/personalData`, memberModel)
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public updatePasswordRequest(passwordModel: APIv1.MembersChangeMemberPassword.Parameters.Model): Observable<APIv1.MembersChangeMemberPassword.Responses.$200> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/my/changePassword`, passwordModel)
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public resetForgottenPassword(model: APIv1.MembersForgotPasswordReset.Parameters.Model): Observable<APIv1.MembersForgotPasswordReset.Responses.$200> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/auth/member/resetForgottenPassword`, model)
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public confirmEmailAddress(Token: string): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/auth/member/confirmMemberEmail`, {
            Token
        })
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public validateMemberPasswordResetToken(Token: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/validateForgotPasswordToken`, {
            Token
        })
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public validateMemberConfirmEmailToken(Token: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/validateEmailConfirmationToken`, {
            Token
        })
            .pipe(
                catchError((ex) => throwError(ex))
            );
    }

    public validateMemberByMemberCard(value: string): Observable<boolean> {

        return this.validateLogin(value, OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN).pipe(
            map((res) => {
                if (res) {
                    if (res.MemberId) {
                        return res.IsOnlineRegistered === false;
                    } else {
                        return null;
                    }
                }

                return null;
            })
        );
    }

    public validateMemberByProperty(
        login: string,
        MobilePhoneCountryId: number,
        type: OLO.Enums.LOGIN_TYPE = OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN,
        memberId: number = null
    ): Observable<boolean> {
        /* Can user sign up/in */

        let validatedProperty: string;

        switch (type) {
            case OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN:
                validatedProperty = 'IsMobileValidated';
                break;
            case OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN:
                validatedProperty = 'IsEmailValidated';
                break;
            default:
                validatedProperty = 'IsMobileValidated';
        }

        const postModel: APIv3.ValidateMemberLoginRequest = {
            Login: login,
            LoginType: type,
            MobilePhoneCountryId
        };

        return this.httpClient.post<APIv3.ValidateMemberLoginResponse>(`${this.config.api.base}/auth/member/validateLogin`, postModel)
            .pipe(
                map(response => {
                    if (memberId) {
                        if (response.Id === memberId) {
                            return true;
                        }
                    }

                    return !response[validatedProperty];
                })
            );
    }

    public isMobileNumberRegistered(Login: string, MobilePhoneCountryId: number): Observable<boolean> {
        const postModel: APIv3.ValidateMemberLoginRequest = {
            Login,
            LoginType: OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN,
            MobilePhoneCountryId
        };


        return this.httpClient.post<APIv3.ValidateMemberLoginResponse>(`${this.config.api.base}/auth/member/validateLogin`, postModel)
            .pipe(
                map(response => response.IsMobileValidated)
            );
    }

    public changeMemberPassword(memberId: number, password: string): Observable<boolean> {
        //
        //  resetPassword ? so clear!!
        //  ... and the best one - password string needs to be double quoted https://media.giphy.com/media/LObjDkMUNFU2oRnXve/giphy.gif
        //
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/my/resetPassword`, `"${password}"`);
    }

    public resetPassword(MemberEmail: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/resendForgotPasswordEmail`, {
            MemberEmail
        });
    }

    public resendEmailConfirmation(model: APIv1.MemberEmailConfirmationRequestModel, LoyaltyAppId: number = null): Observable<boolean> {
        const postModel: APIv1.MemberEmailConfirmationRequestModel = {
            LoyaltyAppId,
            ...model
        };

        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/resendEmailConfirmation`, postModel);
    }

    public resendForgotPasswordConfirmation(MemberEmail: string, LoyaltyAppId: number = null): Observable<boolean> {
        const postModel: APIv1.MemberForgotPasswordRequestModel = {
            LoyaltyAppId,
            MemberEmail,
        };

        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/resendForgotPasswordEmail`, postModel);
    }

    public validateLogin(login: string, loginType: OLO.Enums.LOGIN_TYPE): Observable<APIv3.MemberModel> {
        let postModel: APIv3.ValidateMemberLoginRequest = {
            Login: login,
            LoginType: loginType,
        };

        const [countryId, phone] = login.split(':');
        if (loginType === OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN) {
            postModel.Login = phone;
            postModel.MobilePhoneCountryId = parseInt(countryId, 10);
        }

        return this.httpClient.post<APIv3.ValidateMemberLoginResponse>(`${this.config.api.base}/auth/member/validateLogin`, postModel)
            .pipe(
                map((response: APIv3.ValidateMemberLoginResponse) => ({
                    MemberId: response.Id,
                    MobilePhone: loginType === OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN ? phone : null,
                    Email: loginType === OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN ? phone : null,
                    MobilePhoneCountryId: parseInt(countryId, 10),
                    IsEmailValidated: response.IsEmailValidated,
                    IsOnlineRegistered: response.IsOnlineRegistered,
                    IsMobileValidated: response.IsMobileValidated
                })),
                catchError(ex => {
                    console.error('Error validating member login', ex);

                    return throwError(ex);
                })
            );
    }

    public apiGetFreeProductsForMemberRequest(memberId: number, redeemed: boolean = false): Observable<APIv1.MemberFreeProductModel[]> {
        return this.httpClient.get<APIv1.MembersGetMemberFreeProducts.Responses.$200>(`${this.config.api.base}/members/my/FreeProducts?returnRedeemedProducts=${redeemed}`)
            .pipe(
                map(response => response.Items)
            );
    }


    public apiGetLoyaltyProductsForMemberRequest(): Observable<APIv1.GetLoyaltyProductProgramTrackingBusinessModel[]> {
        return this.httpClient.get<APIv1.MembersGetLoyaltyProductProgramTrackings.Responses.$200>(`${this.config.api.base}/members/my/LoyaltyProducts`)
            .pipe(
                map(response => response.Items)
            );
    }

    public apiGetMemberAccountBalance(): Observable<APIv2.MembersGetMemberAccountBalance.Responses.$200> {
        return this.httpClient.get<APIv2.MembersGetMemberAccountBalance.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/accountBalance`);
    }


    public async resendTemporaryVerificationCode(): Promise<boolean> {
        return new Promise(resolve => {
            this.store
                .pipe(
                    select(selectors.getMemberPhoneNo),
                    take(1),
                ).subscribe(phoneNo => {
                    if (!phoneNo) return resolve(false);

                    this.store.dispatch(actions.MemberVerifyPhoneRestoreFlags());

                    this.store.dispatch(actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo }));

                    resolve(true);
                });
        });
    }

    public getHistoryTransactions(pageSize: number = 10, pageNo: number = 1): Observable<APIv3.TransactionBusinessModel[]> {
        return this.httpClient
            .get<APIv3.MembersGetMemberTransactions.Responses.$200>(`${this.config.api.base}/members/my/transactions?pagingArgs.pageSize=${pageSize}&pagingArgs.pageNo=${pageNo}`)
            .pipe(
                map(items => items.Items)
            );
    }

    public getLatestTransactions(params: APICommon.IMemberLatestTransactionsRequestParam = {
        pageNo: 1,
        pageSize: 10,
    }): Observable<APIv3.LoyaltyAppTransactionModel[]> {
        const p = Utils.HTTP.object2string(params);

        return this.httpClient
            .get<APIv3.MembersGetMemberNewestTransactions.Responses.$200>(`${this.config.api.base}/members/my/latestTransactions${p}`)
            .pipe(
                map(items => items.Items)
            );
    }

}
