export class WeekCount {
    private readonly dateRegExpCheck: RegExp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{1,}Z?$/i;
    private _extractedDateString: string;
    private _firstDayOfAYear: Date;
    private _firstDayOfCurrentYear: number;
    private _differenceInDays: number;

    constructor(
        private _dateToCheck: string,
    ) {
        if (typeof this._dateToCheck !== 'string' || !this.dateRegExpCheck.test(this._dateToCheck)) {
            throw new Error('Invalid date string provided' + _dateToCheck);
        }
        this._init();
    }

    private _init(): void {
        this._extractDateString();
        this._setDateOfTheFirstDayForCurrentYear();
        this._setDayNoOfCurrentYearIncludingSundayAsFirstDayOfWeek();
        this._calculateDaysDifference();
    }

    private _extractDateString(): void {
        this._extractedDateString = this._dateToCheck.split('T')[0];
    }

    private _setDateOfTheFirstDayForCurrentYear(): void {
        this._firstDayOfAYear = new Date(`${this._extractedDateString.split('-')[0]}-01-01T00:00:00.000Z`);
    }

    private _setDayNoOfCurrentYearIncludingSundayAsFirstDayOfWeek(): void {
        this._firstDayOfCurrentYear = this._firstDayOfAYear.getDay() + 1;
    }

    private _calculateDaysDifference(): void {
        const convertedProvidedDate: Date = new Date(`${this._extractedDateString}T00:00:00.000Z`);
        const adjustedDateNumberByFirstDayOfAYear: number = convertedProvidedDate.getTime() + (this._firstDayOfCurrentYear * this._getDayInMiliseconds());

        this._differenceInDays = Math.floor((adjustedDateNumberByFirstDayOfAYear - this._firstDayOfAYear.getTime()) / this._getDayInMiliseconds());
    }

    private _getDayInMiliseconds(): number {
        return (1000 * 60 * 60 * 24);
    }

    public getWeekNo(): number {
        return Math.ceil(this._differenceInDays / 7);
    }
}
