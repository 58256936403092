<ng-container *ngIf="isDevmode">
    <div class="dev-tools{{position ? ' -position-'+position: ''}}{{devBoxVisibility ? ' '+devBoxVisibility : ''}}"
        [ngClass]="{'-sticky': sticky}" #devToolsRoot>
        <div class="dev-tools__inner">
            <div class="dev-tools__hd">
                <button title="hide" class="dev-tools__btn -type-hd -maximize" (click)="hide()">
                    <span class="dev-tools__btn-label">x</span>
                </button>
                <button title="normal size" class="dev-tools__btn -type-hd -normalize" (click)="normalize()">
                    <span class="dev-tools__btn-label">0</span>
                </button>
                <button title="minimize" class="dev-tools__btn -type-hd -minimize" (click)="minimize()">
                    <span class="dev-tools__btn-label">_</span>
                </button>
                <button title="maximize" class="dev-tools__btn -type-hd -maximize" (click)="maximize()">
                    <span class="dev-tools__btn-label">^</span>
                </button>
            </div>
            <div class="dev-tools__filter">

                <input type="text" class="dev-tools__input -type-text" [(ngModel)]="textFilter"
                    (keyup)="handleFilterChange($event)">
            </div>
            <!-- <ng-content select=".dev-tools__bd"></ng-content>
        <ng-content select=".dev-tools__ft"></ng-content> -->

            <div class="dev-tools__bd">{{(state$ | async) | json}}</div>
            <div class="dev-tools__ft">
                <shared-dev-button (click)="restoreState()">RESTORE STATE</shared-dev-button>
                <shared-dev-button (click)="saveState()">SAVE STATE</shared-dev-button>
                <shared-dev-button (click)="toggleDevAutoRestoreState()">AUTO RESTORE STATE {{autoRestoreState}}
                </shared-dev-button>

                <shared-dev-button (click)="openTableNumberModal()">open table number modal</shared-dev-button>
                <shared-dev-button (click)="openDeliveryAddressModal()">open delivery address</shared-dev-button>
                <shared-dev-button (click)="deliveryNotAvailableModal()">delivery not available</shared-dev-button>
                <shared-dev-button (click)="searchForAddress()">search address</shared-dev-button>
                <shared-dev-button (click)="setLinkInfoStep()">set link info step</shared-dev-button>
                <!-- <shared-dev-button (click)="closePanelRight()">Panel right: close</shared-dev-button> -->
                <shared-dev-button (click)="setupGuestDataAfterSignUp()">DEV: setupGuestSignUp</shared-dev-button>
                <shared-dev-button (click)="initPaymentRedirectFlow()">DEV: initPaymentRedirectFlow</shared-dev-button>
                <shared-dev-button (click)="finishPaymentRedirectFlow()">DEV: finishPaymentRedirectFlow
                </shared-dev-button>
            </div>


        </div>
    </div>

    <shared-dev-console></shared-dev-console>
</ng-container>