import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as uuid from 'uuid';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as Models from '@shared/core/models';

import * as StateModels from '../interface';

import { Observable, of, throwError, from } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, delay, take, tap, filter, combineLatest, auditTime } from 'rxjs/operators';

@Injectable()
export class CreditCardEffects {
    @Effect() public requestCardToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.GetCreditCardToken,
                actions.GetCreditCardTokenWithRedirect
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCartLocationNo)
                    ),
            ),
            switchMap(([action, locationNo]) => {
                if (this._config.demoMode === true) return of(actions.__DEMO__getCardToken(action));

                if(this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
                    return [
                        actions.CreditCardsSuccessRequestToken({
                            cardNumber: action.cardNumber,
                            saveCard: action.saveCard,
                            expiryDate: null,
                            isDefaultPaymentMethod: action.isDefaultPaymentMethod,
                            adyenPaymentData: action.adyenPaymentData,
                        })
                    ];
                }

                const cardType = Utils.CreditCards.detectCardType(action.cardNumber);
                const expiryDate: string = Utils.CreditCards.dateToApiFormat(action.expiryDate);

                return this._creditCardsService.requestCardTokenForDefaultPaymentProvider({
                    cardNumber: action.cardNumber,
                    expiryDate
                }, locationNo)
                    .pipe(
                        switchMap(({ token, directPostUrl, returnUrlAfterRedirect }) => {
                            const isDefaultPaymentMethod = typeof action.isDefaultPaymentMethod !== 'boolean' ? true : action.isDefaultPaymentMethod;
                            const saveCard = typeof action.saveCard === 'boolean' ? action.saveCard : false;

                            const successData: State.ICreditCardTokenResponse = {
                                token,
                                cardNumber: action.cardNumber,
                                expiryDate,
                                cardType,
                                saveCard: action.saveCard,
                                isDefaultPaymentMethod,
                                directPostUrl: directPostUrl || null,
                                returnUrlAfterRedirect: returnUrlAfterRedirect || null,
                            };
                            if (action.type === actions.GetCreditCardTokenWithRedirect.type) {

                                const card = new Models.CreditCardBuilder()
                                    .setPaymentProvider(this._config.paymentProvider)
                                    .setType(cardType)
                                    .setNumber(action.cardNumber)
                                    .setExpiryDate(action.expiryDate)
                                    .setToken(null)
                                    .setIsDefault(isDefaultPaymentMethod)
                                    .setSaveCard(saveCard)
                                    .setValidationStatus('validating')
                                    .build();


                                return [
                                    actions.AddCardToState({ card }),
                                    actions.CreditCardsSuccessRequestTokenWithRedirect(successData)
                                ];
                            }

                            return of(actions.CreditCardsSuccessRequestToken(successData));
                        }),
                        catchError(ex => {
                            console.warn('cc errror', ex);

                            return of(actions.CreditCardsErrorRequestToken({ ex }));
                        })
                    );
            })
        );

    @Effect() public validateCardOnAfterRedirect$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsValidateRequest),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getLoyaltyAppSettings),
                    filter(appSettings => appSettings.data !== null),
                    take(1),
                    switchMap(appSettings => this._store
                        .pipe(
                            select(selectors.getCardState),
                            take(1),
                            withLatestFrom(
                                this._store
                                    .pipe(
                                        select(
                                            selectors.getCartLocationNo
                                        )
                                    ),
                            ),
                            switchMap(([state, cartLocationNo]) => {
                                if (this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
                                    return [
                                        actions.CreditCardsValidateSuccessRequest({ responseParams: {
                                            token: null,
                                            ...action.responseParams
                                        }, card: null })
                                    ];
                                }

                                return this._paymentExpressPaymentProviderService.getCardDetails({
                                    sessionToken: state.sessionToken,
                                    appId: appSettings.data.Id,
                                    locationNo: cartLocationNo
                                })
                                    .pipe(
                                        map((response: APIv2.PaymentExpressCardIdResponse) => actions
                                            .CreditCardsValidateSuccessRequest({ responseParams: {
                                                token: null,
                                                ...action.responseParams
                                            }, card: response })),
                                        catchError(ex => {
                                            console.error('Unable to get payment express card details', ex);

                                            return of(actions.CreditCardsValidateErrorRequest({ responseParams: {
                                                token: null,
                                                ...action.responseParams
                                            } }));
                                        })
                                    );

                            })
                        )),
                    catchError(ex => {
                        console.error('Unable to get payment express card details', ex);

                        return of(actions.CreditCardsValidateErrorRequest({ responseParams: {
                            token: null,
                            ...action.responseParams
                        } }));
                    })
                ))
        );

    @Effect() public checkCardsToSaveCardAfterSuccessfulRedirectReturn$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsValidateSuccessRequest
            ),
            switchMap(action => this._store
                .pipe(
                    select(selectors.getCards),
                    take(1),
                    switchMap(cards => {
                        const payloadToken: string = action.responseParams.token || action.card.CardId;
                        const card: OLO.Members.IMemberCreditCardDetails = cards
                            .find(obj => obj.Token === payloadToken && obj.SaveAwait === true && obj.Id === null);

                        if (!card) return [];

                        return of(actions.CreditCardsAddAfterRedirectRequest({ card }));
                    })
                ))
        );

    @Effect() public saveCardAfterSuccessfulRedirectReturnCheck$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsAddAfterRedirectRequest
            ),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCardState)
                    ),
                this._store
                    .pipe(
                        select(selectors.getCart)
                    )
            ),
            switchMap(([action, state, cart]) => {
                const cardBuilder = new Models.CreditCardBuilder()
                    .setPaymentProvider(this._config.paymentProvider)
                    .setType(action.card.CardType)
                    .setNumber(action.card.NiceName || action.card.DisplayName)
                    .setExpiryDate(action.card.ExpirationDate)
                    .setToken(action.card.Token)
                    .setIsDefault(!!action.card.IsDefault)
                    .setLocationNo(cart.locationNo);

                if (this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
                    cardBuilder.setFatZebraToken(state.fatZebra.r, state.fatZebra.v);
                }

                return this._creditCardsService.addMemberCard(cardBuilder.build())
                    .pipe(
                        switchMap(response => [
                            actions.SelectActiveCreditCardId({ cardId: response.Id }),
                            actions.CreditCardsAddAfterRedirectSuccessRequest({ card: action.card, newCard: response }),
                        ]),
                        catchError(ex => of(actions.CreditCardsAddAfterRedirectErrorRequest({ card: action.card, ex })))
                    );
            })

        );

    @Effect() public onGetTokenSuccessSaveOrSetupCardStateForPayment$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.CreditCardsSuccessRequestToken),
            switchMap(action => {
                let token = action.token;

                let card: Models.CreditCard;
                if(this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
                    token = uuid.v4();
                    card = new Models.CreditCardBuilder()
                        .setPaymentProvider(this._config.paymentProvider)
                        .setToken(token)
                        .setNumber(action.cardNumber)
                        .setValidationStatus('success')
                        .setSaveCard(action.saveCard)
                        .setAdyenPaymentData(action.adyenPaymentData)
                        .build();
                } else {
                    card = new Models.CreditCardBuilder()
                        .setPaymentProvider(this._config.paymentProvider)
                        .setNumber(action.cardNumber)
                        .setType(action.cardType)
                        .setExpiryDate(action.expiryDate)
                        .setToken(action.token)
                        .setIsDefault(action.isDefaultPaymentMethod)
                        .setValidationStatus(action.saveCard ? 'validating' : 'success')
                        .build();
                }

                let saveCard = this._config.paymentProvider !== OLO.Enums.PAYMENT_PROVIDER.ADYEN && action.saveCard;
                if (saveCard) {
                    return of(actions.CreditCardsAddRequest({ card }));
                } else {
                    return [
                        actions.AddCardToState({ card }),
                        actions.SelectActiveCreditCardToken({ token })
                    ];
                }

            })
        );

    @Effect() public onCreditCardAddRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAddRequest),
            switchMap(action => this._creditCardsService.addMemberCard(action.card)
                .pipe(
                    map(response =>
                        response ?
                            actions.CreditCardsAddSuccessRequest({ newCard: response }) :
                            actions.CreditCardsAddErrorRequest({})),
                    catchError(ex => of(actions.CreditCardsAddErrorRequest({ ex })))
                ))
        );

    @Effect() public onCreditCardAddRequestSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAddSuccessRequest),
            switchMap(action => [
                actions.CreditCardsRequest(),
                actions.SelectActiveCreditCardId({ cardId: action.newCard.Id }),
                actions.CreditCardShowForm({ isAdding: false }),
            ])
        );

    @Effect() public onCreditCardsRequest$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsRequest),
            switchMap(action => this._creditCardsService.getCardItems()
                .pipe(
                    map((freshCardsList) =>
                        freshCardsList ?
                            actions.CreditCardsSuccessRequest({ payload: freshCardsList.Items }) :
                            actions.CreditCardsErrorRequest({})),
                    catchError(ex => of(actions.CreditCardsErrorRequest({ ex })))
                ))
        );

    @Effect() public selectDefaultPaymentMethod$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsSuccessRequest,
                actions.MemberAccountBalanceSuccessRequest,
            ),
            switchMap(() => this._store
                .pipe(
                    select(selectors.memberHasAvailableBalanceToPayForCartOrder),
                    filter(hasAccountAvailable => this._config.accountCharge?.enabled && hasAccountAvailable !== null || this._config.accountCharge?.enabled !== true),
                    take(1),
                    withLatestFrom(
                        this._store
                            .pipe(
                                select(selectors.getCardState),
                                take(1)
                            )
                    ),
                    tap(([hasAccountAvailable, state]) => {
                        if (!hasAccountAvailable && state.data?.length === 0) {
                            this._store.dispatch(actions.CreditCardShowForm({ isAdding: true }));
                        } else {
                            this._store.dispatch(actions.CreditCardShowForm({ isAdding: false }));
                        }
                    }),
                    switchMap(([hasAccountAvailable, state]) => {
                        if (state.activeCardId || state.activeCardToken) return [];

                        const hasUnsavedCreditCard = state.data?.find(obj => obj.Id === null
                                && obj.Token === null
                                && (obj.SaveAwait === true
                                    || obj.ValidationStatus === 'validating'
                                    || obj.ValidationStatus === 'error'
                                ));
                        if (hasUnsavedCreditCard) return [];

                        if (hasAccountAvailable) {
                            return of(actions.SelectActiveCreditCardId({ cardId: -1 }));
                        }
                        const defaultCard = state.data.find(obj => obj.IsDefault === true && obj.Id !== null);

                        if (defaultCard) {
                            return of(actions.SelectActiveCreditCardId({ cardId: defaultCard.Id }));
                        } else if (state.data.length > 0) {
                            const firstAvailableCard = state.data.find(obj => obj.Id !== null && obj.Id !== undefined);
                            if (firstAvailableCard) {
                                return of(actions.SelectActiveCreditCardId({ cardId: firstAvailableCard.Id }));
                            }
                        }

                        return [];
                    })
                ))
        );

    @Effect() public onCreditCardRemove$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsRemoveRequest),
            switchMap(({ cardId }) => {
                if (cardId !== 0) {
                    return this._creditCardsService.removeMemberCardRequest(cardId)
                        .pipe(
                            map(response =>
                                response ?
                                    actions.CreditCardsRemoveSuccessRequest({ cardId }) :
                                    actions.CreditCardsRemoveErrorRequest({})),
                            catchError(ex => of(actions.CreditCardsRemoveErrorRequest({ ex })))
                        );
                } else {
                    return [actions.CreditCardsRemoveSuccessRequest({ cardId })];
                }
            })
        );

    /* DEMO MODE */
    @Effect() public __DEMO__requestConvergeCardToken$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.__DEMO__getCardToken),
            withLatestFrom(
                this._store
                    .pipe(
                        select(selectors.getCartLocationNo)
                    )
            ),
            delay(2000),
            switchMap(([action, locationNo]) => {
                const cardType = Utils.CreditCards.detectCardType(action.cardNumber);

                return of(actions.__DEMO__CreditCardsSuccessRequestToken({
                    token: `demo-mode-${new Date().getTime()}`,
                    cardNumber: action.cardNumber,
                    expiryDate: action.expiryDate,
                    cardType,
                    saveCard:
                    action.saveCard
                }));
            })
        );

    @Effect() public __DEMO__onGetConvergeTokenSuccessSaveOrSetupCardStateForPayment$: Observable<any> = this._actions$
        .pipe(
            ofType(actions.__DEMO__CreditCardsSuccessRequestToken),
            switchMap(action => {

                const model: OLO.Members.IMemberCreditCardDetails = {
                    ExpirationDate: action.expiryDate,
                    CardType: action.cardType,
                    Token: action.token,
                    DisplayName: action.cardNumber.substring(action.cardNumber.length - 4),
                    Id: null,
                    ValidationStatus: 'success',
                };

                return [
                    actions.AddCardToState({ card: model }),
                    actions.SelectActiveCreditCardToken({ token: action.token })
                ];

            })
        );

    @Effect() public setErrorValidationFlagToCardsAwaitingValidationStatusSuccess$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CreditCardsAddAfterRedirectErrorRequest,
                actions.CreditCardsAddErrorRequest,
                actions.CreditCardsValidateErrorRequest
            ),
            switchMap(() => of(actions.CreditCardsSetErrorValidationStatusToValidatingCards()))
        );

    @Effect() public triggerRequestLocationAdyenConfig$: Observable<Action> = this._actions$
        .pipe(
            ofType(
                actions.CartSetup,
                actions.CartLoad,
                actions.CartSetLocationNo,
                actions.CreditCardsAdyenInit
            ),
            filter(() => this._config.paymentProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN),
            switchMap(() => this._store
                .pipe(
                    select(selectors.getCart),
                    take(1),
                    withLatestFrom(
                        this._store
                            .pipe(
                                select(selectors.getCardState)
                            )
                    ),
                    filter(([cart, cards]) => cart.locationNo !== cards.adyen.locationConfig.locationNo
                        && cards.adyen.locationConfig.isDownloading === false),
                    switchMap(([{ locationNo }]) => [
                        actions.CreditCardsAdyenConfigRequest({ locationNo })
                    ])
                ))
        );

    @Effect() public getAdyenLocationConfig$: Observable<Action> = this._actions$
        .pipe(
            ofType(actions.CreditCardsAdyenConfigRequest),
            switchMap(({ locationNo }) => this._creditCardsService.requestAdyenPreconfigurationSetup(locationNo)
                .pipe(
                    map(config => actions.CreditCardsAdyenConfigSuccessRequest({ locationNo, config })),
                    catchError(ex => {
                        console.error('Unable to get configuration for location');

                        return [
                            actions.CreditCardsAdyenConfigErrorRequest({ locationNo })
                        ];
                    })
                )
            )
        );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<StateModels.IStateShared>,
        private _actions$: Actions,
        private _creditCardsService: Services.CreditCardsService,
        private _paymentExpressPaymentProviderService: Services.PaymentExpressPaymentProviderService
    ) { }

}
