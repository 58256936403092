import { createAction, props } from '@ngrx/store';
import { IStateShared, IPaymentCreditCardData } from '../interface';

export const PaymentReset = createAction('[Payment] Reset');

export const PaymentInitAramark = createAction(
    '[Payment] Init - trigger proper effect and create order',
    (creditCard: IPaymentCreditCardData = null, member: APIv1.PartialMemberModel | APICommon.IOnlineOrderPartialMember = null) => ({ creditCard, member })
);

export const PaymentInit = createAction(
    '[Payment] Init - trigger payment process with prevalidated and generated card token',
);

export const PaymentClearErrors = createAction(
    '[Payment] Clear Errors',
);

/* STEPS */
export const PaymentStepValidateGuestData = createAction(
    '[Payment] Step - validate guest data',
    (creditCard: IPaymentCreditCardData = null) => ({ creditCard })
);

export const PaymentStepValidateGuestCardToken = createAction('[Payment] Step - validate guest get card token');

export const PaymentStepValidateMemberCardToken = createAction('[Payment] Step - validate member card token');

export const PaymentStepCreateOrder = createAction('[Payment] Step - create order');
export const __DEMO__PaymentStepCreateOrder = createAction('[Payment - DEMO] Step - create order');

export const PaymentStepPay = createAction('[Payment] Step - pay');
export const __DEMO__PaymentStepPay = createAction('[Payment - DEMO] Step - pay');

export const PaymentStepPaymentStatusCheck = createAction(
    '[Payment] Step - payment status check',
    props<{ TransactionId: string; OrderId: number }>(),
);
export const __DEMO__PaymentStepPaymentStatusCheck = createAction(
    '[Payment - DEMO] Step - payment status check',
    props<{ TransactionId: string; OrderId: number }>(),
);

export const PaymentStepComplete = createAction(
    '[Payment] Step - payment complete',
    props<{ OrderId: number, payload: APIv2.GetTransactionResponse }>(),
);

export const PaymentStepSkipForZeroPricedOrder = createAction(
    '[Payment] Step - payment skipped for zero priced order'
);

export const PaymentStepFailed = createAction(
    '[Payment] Step = payment failed',
    (Message: string, Id: number = new Date().getTime() + Math.floor(Math.random() * 100000)) => ({ Message, Id })
);
