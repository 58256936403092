import { createAction, props } from '@ngrx/store';

export const LatestTransactionsRequest = createAction(
    '[Latest Transactions] Request latest transactions for member',
    props<{ memberId: number; }>(),
);

export const LatestTransactionsSuccessRequest = createAction(
    '[Latest Transactions] Success request latest transactions for member',
    props<{ memberId: number; payload: APIv3.LoyaltyAppTransactionModel[]; }>(),
);

export const LatestTransactionsErrorRequest = createAction(
    '[Latest Transactions] Error request latest transactions for member',
    props<{ memberId: number; ex?: any; }>(),
);

export const LatestTransactionsReset = createAction(
    '[Latest Transactions] Reset',
);
