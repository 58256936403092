import { LocatioOpenStatus } from './location-open-status';
import { WeekCount } from './week-count';
import { Dates } from './dates.utils';
import { ShortHours } from './short-hours.utils';

import * as moment from 'moment';

export class LocationOpenStatusMessage {
    private _message: string = '';
    private _status!: OLO.Enums.LOCATION_OPEN_STATUS;
    private _dateTimeString!: string;
    private _index: number;
    private _currentOrderTimeInfo: APIv1.LocationOrderingTimeInfoModel;
    private _nextAvailableTimeInfo: APIv1.LocationOrderingTimeInfoModel;

    constructor(
        private _locationOpenStatusConstructor: typeof LocatioOpenStatus,
        private _orderingTimeInfo: APIv1.LocationOrderingTimeInfoModel[],
        private _dateToCheck: Date,
    ) {
        if (!_locationOpenStatusConstructor || typeof _locationOpenStatusConstructor !== 'function') throw new Error(`LocationOpenStatus function is required`);
        if (!_orderingTimeInfo) throw new Error(`Collection of OrderingTimeInfoModel is required`);
        if (!_dateToCheck || !(_dateToCheck instanceof Date)) throw new Error(`Invalid date param`);

        this._init();
    }

    private _init(): void {
        this._dateTimeString = this._extractDateToISOString(this._dateToCheck);
        this._setCurrentOrderTimeInfoByProvidedDateAndGetIndex();
        this._setNextAvailableOpenOrderTimeInfo();
        this._setMessageAndStatus();
    }

    private _extractDateToISOString(date: Date): string {
        return new Date(
            date.getTime() - date.getTimezoneOffset() * 60 * 1000
        ).toISOString();
    }

    private _setCurrentOrderTimeInfoByProvidedDateAndGetIndex(): void {
        this._currentOrderTimeInfo = this._orderingTimeInfo.find((orderTimeInfo, index) => {
            this._index = index;
            return orderTimeInfo.Date.split('T')[0] === this._dateTimeString.split('T')[0];
        });
    }

    private _setNextAvailableOpenOrderTimeInfo(): void {
        this._nextAvailableTimeInfo = this._orderingTimeInfo.find((orderTimeInfo) => {
            const currDate = new Date(this._dateTimeString.replace('Z', ''));
            const nextDate = new Date(orderTimeInfo.Date.split('T')[0] + `T${orderTimeInfo.OpeningTime}`);
            if (nextDate < currDate) return false;

            return this._checkOrderInfoIsOpen(orderTimeInfo);
        });
    }

    private _setMessageAndStatus(): void {
        let shortHour: string = this._setShorHourString();

        if (this._isOpenNow()) {
            this._message = `Open today until ${shortHour}`;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.OPEN;
        }

        if (this._isClosingSoon()) {
            this._message = `Closes soon at ${shortHour}`;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSE_SOON;
        }

        if (this._isOpenTomorrow()) {
            this._message = `Closed. Opens tomorrow at ${shortHour}`;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }

        if (this._isOpeningSoon()) {
            this._message = `Opening soon at ${shortHour}`;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.OPEN_SOON;
        }

        if (this._isClosedNowButOpensLaterToday()) {
            this._message = `Closed. Opens at ${shortHour}`;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }

        if (this._isOpenThisWeek()) {
            this._message = `Closed. Opens ${moment(this._nextAvailableTimeInfo.Date).format('dddd')} at ${shortHour}`;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }

        if (this._isOpenNextWeekOrLater()) {
            this._message = `Closed. Opens ${moment(this._nextAvailableTimeInfo.Date).format('ddd, D MMMM')} at ${shortHour}`;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }

        if (!this._message) {
            this._message = 'Closed';
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }
    }

    private _setShorHourString(): string {
        let shortHour: string = this._shortHour(this._currentOrderTimeInfo?.ClosingTime || null);
        if (!this._isOpenNow()) {
            shortHour = this._shortHour(this._nextAvailableTimeInfo?.OpeningTime || null);
        }

        return shortHour;
    }

    private _isOpenNow(): boolean {
        if (!this._currentOrderTimeInfo) return false;

        const openingDate = new Date(this._currentOrderTimeInfo.Date.split('T')[0] + `T${this._currentOrderTimeInfo.OpeningTime}.000`);
        const closingDate = new Date(this._currentOrderTimeInfo.Date.split('T')[0] + `T${this._currentOrderTimeInfo.ClosingTime}.000`);
        const dateToCheck = new Date(this._dateTimeString.replace('Z', ''));

        const isInTimeRange = dateToCheck >= openingDate && dateToCheck < closingDate;

        return isInTimeRange;
    }

    private _isClosingSoon(): boolean {
        if (!this._currentOrderTimeInfo) return false;

        const currentDate = new Date(this._dateTimeString.replace('Z', ''));
        const closeDate = new Date(this._currentOrderTimeInfo.Date.split('T')[0] + 'T' + this._currentOrderTimeInfo.ClosingTime + '.000');
        const differenceInMinutes = Math.ceil((closeDate.getTime() - currentDate.getTime()) / 60 / 1000);
        return differenceInMinutes <= 60 && this._isOpenNow();
    }

    private _isOpeningSoon(): boolean {
        if (!this._nextAvailableTimeInfo) return false;

        const currentDate = new Date(this._dateTimeString.replace('Z', ''));
        const openDate = new Date(this._nextAvailableTimeInfo.Date.split('T')[0] + 'T' + this._nextAvailableTimeInfo.OpeningTime + '.000');
        const differenceInMinutes = Math.abs(Math.ceil((openDate.getTime() - currentDate.getTime()) / 60 / 1000));

        return differenceInMinutes <= 60 && !this._isOpenNow();
    }

    private _isClosedNowButOpensLaterToday(): boolean {
        if (!this._nextAvailableTimeInfo) return false;

        const currentDate = new Date(this._dateTimeString.replace('Z', ''));
        const openDate = new Date(this._nextAvailableTimeInfo.Date.split('T')[0] + 'T' + this._nextAvailableTimeInfo.OpeningTime + '.000');
        const differenceInMinutes = Math.ceil((openDate.getTime() - currentDate.getTime()) / 60 / 1000);


        return differenceInMinutes > 60 && this._dateTimeString.split('T')[0] === this._nextAvailableTimeInfo.Date.split('T')[0];
    }

    private _isOpenTomorrow(): boolean {
        if (this._index === -1) return false;
        const tomorrowOrderTimeInfo = this._orderingTimeInfo.find((orderingTimeInfo, index) => {
            if (index <= this._index) return false;
            return Dates.datesDiffInDays(this._currentOrderTimeInfo?.Date || this._dateTimeString.replace('Z', ''), orderingTimeInfo.Date) === 1;
        });
        return (tomorrowOrderTimeInfo ? this._checkOrderInfoIsOpen(tomorrowOrderTimeInfo) : false) && !this._isOpenNow();
    }

    private _isOpenNextWeekOrLater(): boolean {
        if (!this._currentOrderTimeInfo && !this._nextAvailableTimeInfo) return false;
        const currentWeekNo = new WeekCount(this._currentOrderTimeInfo?.Date || this._dateTimeString.replace('Z', '')).getWeekNo();
        const nextWeekNo = new WeekCount(this._nextAvailableTimeInfo.Date).getWeekNo();
        return nextWeekNo > currentWeekNo && !this._isOpenNow() && !this._isOpeningSoon();
    }

    private _isOpenThisWeek(): boolean {
        if (!this._nextAvailableTimeInfo) return false;
        const currentWeekNo = new WeekCount(this._currentOrderTimeInfo?.Date || this._dateTimeString.replace('Z', '')).getWeekNo();
        const nextWeekNo = new WeekCount(this._nextAvailableTimeInfo.Date).getWeekNo();
        return nextWeekNo === currentWeekNo && !this._isOpenTomorrow() && !this._isOpenNow() && !this._isClosedNowButOpensLaterToday() && !this._isOpeningSoon();
    }

    private _checkOrderInfoIsOpen(orderTimeInfo: APIv1.LocationOrderingTimeInfoModel): boolean {
        if (!orderTimeInfo) return false;
        return new this._locationOpenStatusConstructor(orderTimeInfo).isOpen();
    }

    private _shortHour(longHourString: string): string {
        if (!longHourString) return null;
        const shortHour = new ShortHours(longHourString).getTransformedHour();
        const withoutMinutesIfPossible = shortHour.replace(':00', '');
        return withoutMinutesIfPossible;
    }

    public getMessageForDate(): string {
        return this._message;
    }

    public getStatus() {
        return this._status;
    }
}
