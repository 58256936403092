import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as uuid from 'uuid';

import * as Tokens from '@shared/core/tokens';
import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';

import { Observable } from 'rxjs';
import { map, withLatestFrom, take, filter, combineLatest } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CreditCardsController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    public getAdyenConfig$(): Observable<State.AdyenLocationConfig> {
        return this._store
            .pipe(
                select(selectors.getAdyenLocationConfig)
            );
    }

    public initAdyen(): void {
        this._store.dispatch(actions.CreditCardsAdyenInit());
    }

    public isAddingCard$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isAddingCardRequest),
            );
    }

    public isDownloadingCardsList$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isDownloadingCardsList)
            );
    }

    public isMakingRequests$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isLoadingCards),
            );
    }

    public showPaymentFormForGuest$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.shouldShowCreditCardForm),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.showAddCardForm)
                        )
                ),
                map(([shouldShowForm, formIsAddingFlag]) => shouldShowForm || formIsAddingFlag),
            );
    }

    public showPaymentFormForMember$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCardState),
                combineLatest(
                    this.isMakingRequests$(),
                    this.isLoadingCardsFirstTime$(),
                    this.isAccountChargeSet$(this._config)
                ),
                filter(([state, isMakingRequests, isLoadingCardsFirstTime]) => isMakingRequests === false), /* Filter this to avoid form disconnection during post */
                map(([state, isMakingRequests, isLoadingCardsFirstTime, hasAccount]) => {
                    if (isMakingRequests || isLoadingCardsFirstTime || hasAccount) return false;

                    if (!state.data || state.data.length === 0) return true;

                    return state.showAddCardForm;
                })
            );
    }

    public canChangePaymentDetailsForGuest$(): Observable<boolean> {
        return this.showPaymentFormForGuest$()
            .pipe(
                map(showForm => !showForm),
            );
    }

    public canChangePaymentDetailsForMember$(): Observable<boolean> {
        return this.showPaymentFormForMember$()
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getCardState),
                        ),
                    this._store
                        .pipe(
                            select(selectors.isUnsavedCardSelected)
                        ),
                    this.isMakingRequests$(),
                ),
                map(([showForm, state, isUnsavedCardSelected, isMakingRequests]) => {
                    if (isMakingRequests) return false;

                    const containsUnsavedCard = state.data.some(obj => obj.Id === null);

                    return !showForm && containsUnsavedCard && isUnsavedCardSelected;
                }),
            );
    }

    public canMemberAddAnotherCard$(): Observable<boolean> {
        return this.showPaymentFormForMember$()
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getCardState)
                        ),
                    this.isMakingRequests$(),
                    this.isAccountChargeSet$(this._config)
                ),
                map(([showForm, state, isMakingRequests, hasAccount]) => {
                    if (isMakingRequests) return false;
                    if (hasAccount) return true;

                    return showForm ? false : state.data.length > 0;
                }),
            );
    }

    public isAccountChargeSet$(config: IConfig): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isAccountSet(config))
            );
    }

    public getCardsList$(): Observable<OLO.Members.IMemberCreditCardDetails[]> {
        return this._store
            .pipe(
                select(selectors.getCards),
            );
    }

    public getActiveCardDetails$(): Observable<OLO.Members.IMemberCreditCardDetails> {
        return this._store
            .pipe(
                select(selectors.getActiveCardDetails)
            );
    }

    public isCardSelected$(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isCardSelected(creditCard)),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled),
                        )
                ),
                map(([isCardSelected, isGuest]) => {
                    if (isGuest) return true;

                    return isCardSelected;
                })
            );
    }

    public cardErrorMessages(creditCard: OLO.Members.IMemberCreditCardDetails): { [key: string]: string; } {
        if (creditCard.Id) return { cardRetry: null };

        return { cardChange: null };
    }

    public selectedCardIsOk$(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this.isCardSelected$(creditCard)
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getPaymentErrors)
                        ),
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        ),
                    this._store
                        .pipe(
                            select(selectors.getCardState)
                        )
                ),
                map(([isSelected, errors, guestMode, cardsState]) => {
                    if (!guestMode) return isSelected && errors.length === 0;

                    if (isSelected && cardsState.validation.hasFailed === true || errors.length > 0) return false;

                    return isSelected;
                })
            );
    }

    public selectedCardHasError$(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this.isCardSelected$(creditCard)
            .pipe(
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getPaymentErrors)
                        ),
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        ),
                    this._store
                        .pipe(
                            select(selectors.getCardState)
                        )
                ),
                map(([isSelected, errors, guestMode, cardsState]) => {
                    const savedHasError = !guestMode && isSelected && errors.length !== 0;

                    const validationFailed: boolean = creditCard.ValidationStatus === 'error' && isSelected;
                    const removeFailed: boolean = cardsState.remove.id !== null && cardsState.remove.id === creditCard.Id && cardsState.remove.hasFailed;
                    if (validationFailed || removeFailed || savedHasError) return true;

                    return false;
                }),
            );
    }

    public getActiveCardRedirectUrl$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getActiveCardRedirectUrl)
            );
    }

    public canCancelAddingNewCardsForm$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasCreditCardsDefined),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.getCardState)
                        )
                ),
                map(([hasCardsDefined, state]) => {
                    if (state.activeCardRedirectUrl && state.data && state.data.length === 1) return false;

                    return hasCardsDefined;
                })
            );
    }

    public isLoadingCardsFirstTime$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isRequestingCardsFirstTime),
            );
    }

    public hasDownloadedCards$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.hasDownloadedCards),
            );
    }

    public hasCards$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getCardState),
                combineLatest(
                    this.isLoadingCardsFirstTime$()
                ),
                filter(([state, isLoadingFirstTime]) => isLoadingFirstTime === false),
                map(([state, isLoadingFirstTime]) => state.data && state.data.length > 0)
            );
    }

    public isRemovingCard(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isRemovingCard(creditCard.Id))
            );
    }

    public removeCardFailed(creditCard: OLO.Members.IMemberCreditCardDetails): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.removeFailedForCard(creditCard.Id))
            );
    }

    public canShowNoPaymentMethodDefined$(): Observable<boolean> {
        return this.hasCards$()
            .pipe(
                combineLatest(
                    this.hasDownloadedCards$(),
                    this._store
                        .pipe(
                            select(selectors.showAccountPaymentMethod(this._config))
                        ),
                ),
                map(([hasCards, hasDownloaded, hasAccountPaymentMethod]) => hasDownloaded === true && hasCards === false && !hasAccountPaymentMethod)
            );
    }

    public resetCreditCardErrors(): void {
        this._store.dispatch(actions.CreditCardsResetError());
    }

    public addCreditCard(cardData: OLO.CreditCards.ICreditCardDetails): void {
        this._store.dispatch(actions.GetCreditCardToken(cardData));
    }

    public hideAddCardForm(): void {
        this._store.dispatch(actions.CreditCardShowForm({ isAdding: false }));
    }

    public toggleCreditCardFormVisibilityWithCleanUp(isAdding: boolean = true): void {
        this._store.dispatch(actions.CreditCardsClearAllUnsavedCards());
        this._store.dispatch(actions.CreditCardShowForm({ isAdding }));
    }

    public removeAllUnsavedCards(): void {
        return this._store.dispatch(actions.CreditCardsClearAllUnsavedCards());
    }

    public resetCreditCardDetails(): void {
        this._store
            .pipe(
                select(selectors.isPaying),
                take(1)
            ).subscribe(isPaying => {
                if (isPaying) return;

                this._store.dispatch(actions.PaymentReset());
                this._store.dispatch(actions.CreditCardsStateReset());
            });
    }

    public removeUnsavedSelectedCard(): void {
        this._store
            .pipe(
                select(selectors.getCardState),
                take(1)
            ).subscribe(state => {
                const activeToken = state.activeCardToken;
                const card = state.data.find(obj => obj.Token === activeToken && obj.Id === null);

                if (!card) return;

                this._store.dispatch(actions.CreditCardsRemoveUnsavedCard({ token: activeToken }));
            });
    }

    public selectCardForPayment(card: OLO.Members.IMemberCreditCardDetails): void {
        this._store
            .pipe(
                select(selectors.isPaying),
                take(1)
            ).subscribe(isPaying => {
                if (isPaying) return;

                this._store.dispatch(actions.PaymentReset());
                this._store.dispatch(actions.CreditCardsResetError());
                if (card?.Id) {
                    return this._store.dispatch(actions.SelectActiveCreditCardId({ cardId: card.Id }));
                }
                if (card?.Token) {
                    return this._store.dispatch(actions.SelectActiveCreditCardToken({ token: card.Token }));
                }
            });
    }

    public requestCardsList(): void {
        this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.isDownloading === false),
                withLatestFrom(
                    this._store
                        .pipe(
                            select(selectors.isMemberAuthorizedJWT)
                        ),
                    this._store
                        .pipe(
                            select(selectors.isGuestModeEnabled)
                        ),
                ),
                take(1),
            ).subscribe(([state, isAuthorized, isGuest]) => {
                if (isAuthorized && !isGuest) {
                    this._store.dispatch(actions.CreditCardsRequest());
                }
            });
    }

    public removeCreditCard(card: OLO.Members.IMemberCreditCardDetails): void {
        return this._store.dispatch(actions.CreditCardsRemoveRequest({ cardId: card.Id }));
    }

    public memberCardErrorHandler(card: OLO.Members.IMemberCreditCardDetails): void {
        this._store.dispatch(actions.PaymentReset());
    }

    public isAccountChargeSelected$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isAccountChargeSelected)
            );
    }
}
