import { MemoizedSelector, createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import * as Models from '@shared/core/models';
import * as State from './interface';

import * as fromAlertMessages from './alertMessages/alert-messages.selectors';
import * as fromAppSettings from './appSettings/app-settings.selectors';
import * as fromAvailablePickups from './availablePickups/available-pickups.selectors';
import * as fromCart from './cart/cart.selectors';
import * as fromCartPopup from './cartPopup/cart-popup.selectors';
import * as fromCollectionType from './collectionType/collection-type.selectors';
import * as fromCreditCards from './creditCards/credit-cards.selectors';
import * as fromCurrentLocation from './currentLocation/current-location.selectors';
import * as fromDietaryTagsImages from './dietaryTagsImages/dietary-tags-images.selectors';
import * as fromFreeProductsImages from './freeProductsImages/free-products-images.selectors';
import * as fromGeolocation from './geolocation/geolocation.selectors';
import * as fromGoogle from './google/google.selectors';
import * as fromHistoryOrders from './historyOrders/history-orders.selectors';
import * as fromIngredients from './ingredients/ingredients.selectors';
import * as fromLatestTransactions from './latestTransactions/latest-transactions.selectors';
import * as fromLoader from './loader/loader.selectors';
import * as fromLocations from './locations/locations.selectors';
import * as fromLocationsFilters from './locationsFilters/locations-filters.selectors';
import * as fromLocationsImages from './locationsImages/locations-images.selectors';
import * as fromLoyaltyIntroductionPages from './loyaltyIntroductionPages/loyalty-introduction-pages.selectors';
import * as fromLoyaltyIntroductionPagesImages from './loyaltyIntroductionPagesImages/loyalty-introduction-pages-images.selectors';
import * as fromLoyaltyMessages from './loyaltyMessages/loyalty-messages.selectors';
import * as fromLoyaltyProductPrograms from './loyaltyProductPrograms/loyalty-product-programs.selectors';
import * as fromLoyaltyPrograms from './loyaltyPrograms/loyalty-programs.selectors';
import * as fromMemberCardBarcodesImages from './memberCardBarcodesImages/member-card-barcodes-images.selectors';
import * as fromMembers from './members/members.selectors';
import * as fromMenuFlowDefaultActivations from './menuFlowDefaultActivations/menu-flow-default-activations.selectors';
import * as fromMenuFlowImages from './menuFlowImages/menu-flow-images.selectors';
import * as fromMenuFlows from './menuFlows/menu-flows.selectors';
import * as fromModals from './modals/modal.selectors';
import * as fromOnlineMenu from './onlineMenu/online-menu.selectors';
import * as fromOnlineMenuFilters from './onlineMenuFilters/online-menu-filters.selectors';
import * as fromOnlineMenuPagesImages from './onlineMenuPagesImages/online-menu-pages-images.selectors';
import * as fromOnlineMenuProductsImages from './onlineMenuProductsImages/online-menu-products-images.selectors';
import * as fromOnlineOrder from './onlineOrder/online-order.selectors';
import * as fromOrderTypes from './orderTypes/order-types.selectors';
import * as fromOrderTypesImages from './orderTypesImages/order-types-images.selectors';
import * as fromPayment from './payment/payment.selectors';
import * as fromProductImages from './productImages/product-images.selectors';
import * as fromProductRestrictions from './productRestrictions/product-restrictions.selectors';
import * as fromReorder from './reorder/reorder.selectors';
import * as fromRouter from './router/router.selectors';
import * as fromTopBar from './topBar/top-bar.selectors';
import * as fromVenuesImages from './venuesImages/venues-images.selectors';
import * as fromWizzard from './wizzard/wizzard.selectors';

export * from './alertMessages/alert-messages.selectors';
export * from './appSettings/app-settings.selectors';
export * from './availablePickups/available-pickups.selectors';
export * from './cart/cart.selectors';
export * from './cartPopup/cart-popup.selectors';
export * from './collectionType/collection-type.selectors';
export * from './creditCards/credit-cards.selectors';
export * from './currentLocation/current-location.selectors';
export * from './dietaryTagsImages/dietary-tags-images.selectors';
export * from './freeProductsImages/free-products-images.selectors';
export * from './geolocation/geolocation.selectors';
export * from './google/google.selectors';
export * from './historyOrders/history-orders.selectors';
export * from './ingredients/ingredients.selectors';
export * from './latestTransactions/latest-transactions.selectors';
export * from './loader/loader.selectors';
export * from './locations/locations.selectors';
export * from './locationsFilters/locations-filters.selectors';
export * from './locationsImages/locations-images.selectors';
export * from './loyaltyIntroductionPages/loyalty-introduction-pages.selectors';
export * from './loyaltyIntroductionPagesImages/loyalty-introduction-pages-images.selectors';
export * from './loyaltyMessages/loyalty-messages.selectors';
export * from './loyaltyProductPrograms/loyalty-product-programs.selectors';
export * from './loyaltyPrograms/loyalty-programs.selectors';
export * from './memberCardBarcodesImages/member-card-barcodes-images.selectors';
export * from './members/members.selectors';
export * from './menuFlowDefaultActivations/menu-flow-default-activations.selectors';
export * from './menuFlowImages/menu-flow-images.selectors';
export * from './menuFlows/menu-flows.selectors';
export * from './modals/modal.selectors';
export * from './onlineMenu/online-menu.selectors';
export * from './onlineMenuFilters/online-menu-filters.selectors';
export * from './onlineMenuPagesImages/online-menu-pages-images.selectors';
export * from './onlineMenuProductsImages/online-menu-products-images.selectors';
export * from './onlineOrder/online-order.selectors';
export * from './orderTypes/order-types.selectors';
export * from './orderTypesImages/order-types-images.selectors';
export * from './payment/payment.selectors';
export * from './productImages/product-images.selectors';
export * from './productRestrictions/product-restrictions.selectors';
export * from './reorder/reorder.selectors';
export * from './router/router.selectors';
export * from './topBar/top-bar.selectors';
export * from './venuesImages/venues-images.selectors';
export * from './wizzard/wizzard.selectors';

export const getLocationFiltersSearchInputWithDelivery = (config: IConfig) =>
    createSelector(
        fromCollectionType.isCollectionTypeDelivery(config),
        fromLocationsFilters.getLocationFilters,
        (isDelivery, filters) => (isDelivery ? filters?.address?.structured_formatting?.main_text : filters?.search) || null,
    );

export const getOnlineMenuPageDetails = (
    pageId: number,
    imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb,
): MemoizedSelector<State.IStateShared, OLO.Components.IOnlineMenuPageDetails<State.IOnlineMenuPageImage>> =>
    createSelector(fromOnlineMenu.getOnlineMenu, fromOnlineMenuPagesImages.getOnlineMenuPageImage(pageId, imageType), (onlineMenu, image) => {
        if (!onlineMenu || !onlineMenu.data) return null;

        const foundPage = onlineMenu.data.Pages.find((obj) => obj.Id === pageId);
        if (!foundPage) return null;

        return {
            title: foundPage.Name,
            description: foundPage.Description,
            image: image,
        } as OLO.Components.IOnlineMenuPageDetails<State.IOnlineMenuPageImage>;
    });
export const getOnlineMenuPagesFiltered = createSelector(fromOnlineMenu.getOnlineMenu, fromOnlineMenuFilters.getOnlineMenuFiltersSearchInput, (onlineMenu, searchInput) => {
    const pages = onlineMenu.data?.Pages || null;
    if (!searchInput || !pages) {
        return pages;
    }

    return pages.reduce((pagesAcc, page) => {
        if (page.Products.length === 0) return pagesAcc;

        /* Page check */
        const pageMatches = Utils.Strings.searchValueStringInObject(searchInput, page, 'Name', 'Description');
        if (pageMatches) {
            if (page.Products.length) {
                pagesAcc.push(page);
            }

            return pagesAcc;
        }

        /* Check products */
        const productsFiltered = page.Products.filter((product) => {
            const productMatches = Utils.Strings.searchValueStringInObject(searchInput, product, 'PosDisplay', 'PosDescription');
            if (productMatches) return true;
            /* Check tags if no match in prop values */
            const tagMatches = !product.Tags ? false : product.Tags.some((t) => Utils.Strings.searchValueStringInObject(searchInput, t, 'Name'));

            return tagMatches;
        });

        if (productsFiltered.length) {
            pagesAcc.push({
                ...page,
                Products: productsFiltered,
            });
        }

        return pagesAcc;
    }, [] as APIv1.OnlineMenuPageResponseModel[]);
});

export const getOrderTypesForCartsLocation = createSelector(fromCart.getCartLocationNo, fromOrderTypes.getOrderTypesForAllLocations, (locationNo, orderTypes) => {
    if (!locationNo || orderTypes.length === 0) return null;

    const foundTypes = orderTypes.find((obj) => obj.locationNo === locationNo && obj.data && obj.data.length > 0 && obj.isDownloading === false);
    if (!foundTypes) return null;

    return foundTypes.data;
});

export const canShowOrderTypesSelectors = createSelector(getOrderTypesForCartsLocation, (orderTypes) => {
    if (!orderTypes || orderTypes.length === 0) return false;
    const filtered = orderTypes?.filter(obj => obj.Disclaimers.length > 0 || obj.Details.length > 0);
    if (!filtered.length) return false;
    if (filtered.length > 1) return true;


    return filtered[0].Details.length > 0 || filtered[0].Disclaimers.length > 0;
});

export const canShowPaymentSection = createSelector(
    fromCart.getCartTotalQuantity,
    fromCart.getCartTotalValue,
    (cartTotalQuantity, cartTotalValue) => cartTotalQuantity > 0 && cartTotalValue > 0,
);

export const isLoadingLoyaltyInfoForCurrentMember = createSelector(
    fromMembers.isMemberLoading,
    fromMembers.isDownloadingLoyaltyProducts,
    fromMembers.isDownloadingFreeProducts,
    fromLoyaltyProductPrograms.isDownloadingAnyProgram,
    (memberIsLoading, isLoadingLoyaltyProducts, isLoadingFreeProducts, isLoadingPrograms) =>
        memberIsLoading || isLoadingLoyaltyProducts || isLoadingFreeProducts || isLoadingPrograms,
);

export const getLoyaltyFreeProductsForCurrentMember = createSelector(
    fromMembers.isMemberAuthorizedJWT,
    fromMembers.getMemberState,
    fromLoyaltyProductPrograms.getAllValidLoyaltyPrograms,
    fromLoyaltyProductPrograms.isDownloadingAnyProgram,
    fromMembers.freeValidProducts,
    fromMembers.loyaltyProducts,
    fromLoyaltyProductPrograms.getLoyaltyProductPrograms,
    (isAuthorized, member, programs, isDownloadingAnyProgram, freeProducts, loyaltyProducts, programsState) => {
        if (!isAuthorized || !member.data || !freeProducts || !loyaltyProducts || !loyaltyProducts.data) return null;

        return freeProducts.reduce((acc, product) => {
            if (product.MemberId !== member.data.MemberId) return acc;

            const pr: APIv1.GetLoyaltyProductProgramBusinessModel = programs.find(obj => obj.ProductId === product.ProductId);
            if (!pr) return acc;
            const loyaltyProduct = loyaltyProducts.data.find((obj) => obj.PLU === pr.PLU); /* ROTFL ROTFL ROTFL... */
            if (!loyaltyProduct) return acc;

            const codeName: string = programsState.data?.find((obj) => obj.Id === pr.Id)?.ProgramName;

            return [
                ...acc,
                {
                    isDownloading: isDownloadingAnyProgram || member.freeProducts.isDownloading || member.loyaltyProducts.isDownloading,
                    freeProduct: [product],
                    loyaltyProduct,
                    program: pr,
                    codeName,
                },
            ];
        }, []) as OLO.Ordering.ILoyaltyFreeProductItemModel[];
    },
);

export const getLoyaltyFreeProductsForCurrentMemberGrouped = createSelector(getLoyaltyFreeProductsForCurrentMember, (items) =>
    items !== null
        ? (items.reduce((acc, item) => {
            const codeName = item.codeName;
            const qty = item.program.ProductQauantityToIssue;

            const existingItem = acc.find((obj) => obj.codeName === codeName);
            if (!existingItem) {
                acc.push({
                    ...item,
                    program: { ...item.program },
                    freeProduct: [...item.freeProduct],
                    loyaltyProduct: { ...item.loyaltyProduct },
                });
            } else {
                existingItem.freeProduct.push({ ...item });
                existingItem.program.ProductQauantityToIssue = existingItem.program.ProductQauantityToIssue + qty;
            }

            return acc;
        }, []) as OLO.Ordering.ILoyaltyFreeProductItemModel[])
        : [],
);

export const getLoyaltyProgramProductItemsForCurrentMember = createSelector(
    fromMembers.isMemberAuthorizedJWT,
    fromMembers.getCurrentMember,
    fromLoyaltyProductPrograms.getAllValidLoyaltyPrograms,
    fromMembers.loyaltyProducts,
    fromLoyaltyPrograms.getLoyaltyPrograms,
    (isAuthorized, member, programs, stateProducts, loyaltyPrograms) => {
        if (!isAuthorized || !member || programs?.length === 0) return null;

        return (
            loyaltyPrograms.data?.reduce((acc, loyaltyProgram) => {
                const foundProgram = programs?.find((obj) => obj.Id === loyaltyProgram.LoyaltyProgramId);
                if (!foundProgram) return acc;

                const foundProduct: APIv3.GetLoyaltyProductProgramTrackingBusinessModel = stateProducts?.data?.find(product =>
                    product.MemberId === member.UserId
                && product.LoyaltyProductProgramId === loyaltyProgram.LoyaltyProgramId);

                return [
                    ...acc,
                    {
                        program: foundProgram,
                        product: foundProduct || {
                            CurrentProductCount: 0,
                            Id: null,
                            LoyaltyProductProgramId: loyaltyProgram.LoyaltyProgramId,
                            MemberCardNumber: +member.MemberCardNumber,
                            MemberId: member.UserId,
                            PLU: foundProgram.PLU,
                            ProductDescription: foundProgram.ProductDescription,
                            ProductIssuedCount: 0,
                            ProgramName: foundProgram.ProgramName,
                        },
                        codeName: new Utils.LoyaltyProgramCodeName(loyaltyProgram.Type).getProgramCodeName(),
                        displayOrder: loyaltyProgram.DisplayOrder,
                        isLoading: stateProducts.isDownloading,
                    }
                ].sort((a, b) => a.displayOrder - b.displayOrder);
            }, [] as OLO.Ordering.ILoyaltyProgramProductItemModel[]) || null);
    }
);

export const getOrderingTimeInfoByCartLocation = createSelector(fromCart.getCartLocationNo, fromLocations.getLocationsState, (locationNo, locationState) => {
    if (!locationNo || !locationState.data) return null;

    return locationState.data?.find((obj) => obj.LocationNo === locationNo)?.OrderingTimeInfo;
});

export const getOrderingTimeInfoForTodayByCartLocation = createSelector(getOrderingTimeInfoByCartLocation, (orderingTimeInfo) => {
    if (!orderingTimeInfo) return null;
    const date = new Date();
    const currDay = date.getDay();

    return orderingTimeInfo.find((obj) => obj.DayOfWeek === currDay);
});

export const currentLocationNoByRoute = createSelector(
    fromAppSettings.isInVenueMode,
    fromRouter.getCurrentRoute,
    fromLocations.getLocationsState,
    (isVenueMode, route, locations) => {
        if (!route || !locations || locations.isDownloading || locations.data === null) return undefined;

        if (!isVenueMode) return route.params.id ? +route.params.id : null;

        const foundLocation: APIv1.LocationBusinessModel = locations.data.find((location) => {
            if (location.OnlineOrderingStatus !== 0 || location.LocationOLOIsActive !== true || !location.LocationFriendlyName || !route.params.LocationFriendlyName) return false;

            return location.LocationFriendlyName.toLowerCase().replace(/\s/gi, '') === route.params.LocationFriendlyName.toLowerCase();
        });

        return foundLocation ? foundLocation.LocationNo : null;
    },
);

export const getOrderSummary: MemoizedSelector<State.IStateShared, OLO.Ordering.IOrderSummary> = createSelector(
    fromOnlineOrder.getOnlineOrderState,
    fromOnlineOrder.getOnlineOrderTotalTax,
    ({ recalculateRequest, orderType }, Tax) => {
        if (!recalculateRequest.data || Tax === null) return null;

        return {
            Subtotal: recalculateRequest.data?.TotalNettValue,
            Tax,
            Total: recalculateRequest.data?.TotalLeftToPay,
            Surcharges: (orderType?.Surcharges || []).sort((a, b) => (a.DisplayIndex || 0) - (b.DisplayIndex || 0)),
        };
    },
);

export const memberHasAvailableBalanceToPayForCartOrder = createSelector(fromMembers.getMemberState, getOrderSummary, (member, summary) => {
    if (!summary || !member.data || member.accountBalance.isDownloading === true || !member.accountBalance.data) return null;

    return summary.Total <= member.accountBalance.data?.AvailableBalance;
});

export const getPickupTimeLabelForCart = createSelector(fromLocationsFilters.getLocationFilters, fromCart.getCartPickupTimeName, (filters, cartLabel) => {
    if (!cartLabel && !filters) return null;

    if (cartLabel) return cartLabel;

    if (!filters.pickupTime) return filters.pickupMode.Name;

    return filters.pickupTime.Name;
});

export const locationWithValidation = (locationNo: number): MemoizedSelector<State.IStateShared, APIv1.LocationBusinessModel> =>
    createSelector(fromLocations.getLocationsState, fromCurrentLocation.getCurrentLocationValidationState, (locations, validation) => {
        if (!locations || !locations.data || !locationNo || !validation || !validation.hasSucceeded) return null;

        const foundLocation: APIv1.LocationBusinessModel = locations.data.find((obj) => obj.LocationNo === locationNo);
        if (!foundLocation) return null;

        return foundLocation;
    });

export const currentLocationWithValidation: MemoizedSelector<State.IStateShared, APIv1.LocationBusinessModel> = createSelector(
    fromLocations.getLocationsState,
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentLocationValidationState,
    (locations, locationNo, validation) => {
        if (!locations || !locations.data || !locationNo || !validation || !validation.hasSucceeded) return null;

        const foundLocation: APIv1.LocationBusinessModel = locations.data.find((obj) => obj.LocationNo === locationNo);
        if (!foundLocation) return null;

        return foundLocation;
    },
);

export const currentLocationNameWithValidation: MemoizedSelector<State.IStateShared, string> = createSelector(currentLocationWithValidation, (location) =>
    location ? location.LocationFriendlyName : null,
);

export const canShowOnlineMenuForCurrentLocation = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentLocationValidationState,
    fromOnlineMenu.getOnlineMenu,
    (locationNo, validation, onlineMenu) => {
        if (
            !onlineMenu ||
            !onlineMenu.data ||
            !onlineMenu.data ||
            !onlineMenu.locationNo ||
            !locationNo ||
            onlineMenu.locationNo !== locationNo ||
            onlineMenu.isDownloading ||
            !validation ||
            validation.isValidating ||
            validation.hasFailed ||
            !validation.hasSucceeded
        )
            return false;

        return true;
    },
);

export const wizzardMenuFlowPagesReports: MemoizedSelector<State.IStateShared, State.IWizzardPageReport[]> = createSelector(
    fromWizzard.getWizzard,
    fromMenuFlows.getMenuFlows,
    (wizzard, menuFlows) => {
        if (!wizzard || !wizzard.itemsMenuFlow) return null;

        const menuFlowId: number = wizzard.itemsMenuFlow.MenuFlowId;
        const locationNo: number = wizzard.itemsMenuFlow.LocationNo;
        const menuFlow: State.IMenuFlows = menuFlows.find((obj) => obj.LocationNo === locationNo && obj.MenuFlowId === menuFlowId);

        if (!menuFlow || !menuFlow.data) return null;

        const reports = [];

        for (let i = 0, j = wizzard.itemsMenuFlow.Pages.length; i < j; i++) {
            const wizzardPage: State.IWizzardMenuFlowPage = wizzard.itemsMenuFlow.Pages[i];
            const menuFlowPage: APIv1.MenuFlowPage = menuFlow.data.Pages.find((obj) => obj.PageIdentifier === wizzardPage.PageIdentifier);
            if (!menuFlowPage) return;

            const totalProductsQuantity = wizzardPage.Products.reduce((acc, product) => acc + product.Quantity, 0);

            reports.push({
                pageIdentifier: wizzardPage.PageIdentifier,
                isComplete: totalProductsQuantity >= menuFlowPage.PageMinQuantity && totalProductsQuantity <= menuFlowPage.PageMaxQuantity,
                errors: wizzard.errors && wizzard.errors.length > 0,
            });
        }

        return reports;
    },
);

export const getStatsForWizzardItem = (isMenuFlow: boolean, label: string = 'From: '): MemoizedSelector<State.IStateShared, OLO.Components.IStatsComponentInput> =>
    createSelector(
        fromOnlineMenu.getOnlineMenu,
        fromWizzard.getWizzardSimpleItem,
        fromWizzard.getWizzardMenuFlow,
        fromDietaryTagsImages.getDietaryTagImages,
        (onlineMenu, singleProduct, menuFlow, images) => {
            if (!onlineMenu || !onlineMenu.data || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct) || !images) return null;

            const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;

            let stats: OLO.Components.IStatsComponentInput = null;

            onlineMenu.data.Pages.forEach((page) => {
                page.Products.forEach((product) => {
                    if (stats) return;

                    if (isMenuFlow) {
                        if (product.MenuFlowId === itemId) {
                            stats = {
                                label: product.Price ? label : null,
                                price: product.Price,
                                kilojoules: product.Kilojoules,
                                cals: Math.ceil(product.Kilojoules / 4.184),
                            };
                        }

                        return;
                    }

                    if (product.ProductId === itemId) {
                        stats = {
                            label: null,
                            price: product.Price,
                            kilojoules: product.Kilojoules,
                            cals: Math.ceil(product.Kilojoules / 4.184),
                        };
                    }
                });
            });

            return stats;
        },
    );

export const getDietaryTagImagesForWizzardItem = (isMenuFlow: boolean): MemoizedSelector<State.IStateShared, State.IDietaryTagImage[]> =>
    createSelector(
        fromOnlineMenu.getOnlineMenu,
        fromWizzard.getWizzardSimpleItem,
        fromWizzard.getWizzardMenuFlow,
        fromDietaryTagsImages.getDietaryTagImages,
        (onlineMenu, singleProduct, menuFlow, images) => {
            if (!onlineMenu || !onlineMenu.data || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct) || !images) return null;

            const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;

            let dietaryTags: APIv1.TagModel[];

            onlineMenu.data.Pages.forEach((page) => {
                page.Products.forEach((product) => {
                    if (dietaryTags) return;

                    if (isMenuFlow) {
                        if (product.MenuFlowId === itemId && product.DietaryTags) {
                            dietaryTags = [...product.DietaryTags];
                        }

                        return;
                    }

                    if (product.ProductId === itemId && product.DietaryTags) {
                        dietaryTags = [...product.DietaryTags];
                    }
                });
            });

            if (!dietaryTags) return null;

            return images.reduce((acc, tagImg) => {
                const tag = dietaryTags.find((obj) => obj.Id === tagImg.Id);
                if (tag) {
                    return [...acc, { ...tagImg, Name: tag.Name }];
                }

                return acc;
            }, []);
        },
    );

export const getImageForWizzardItem = (isMenuFlow: boolean) =>
    createSelector(
        fromOnlineMenu.getOnlineMenu,
        fromWizzard.getWizzardSimpleItem,
        fromWizzard.getWizzardMenuFlow,
        fromOnlineMenuProductsImages.getImagesForOnlineMenuPages,
        fromOnlineMenuProductsImages.isDownloadingAnyOnlineMenuPageImages,
        fromCart.getCart,
        (onlineMenu, singleProduct, menuFlow, images, isDownloadingAnything, cart) => {
            if (isDownloadingAnything || !images || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct)) {
                return null;
            }

            const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;
            const isEditing: boolean = isMenuFlow ? (menuFlow._Id ? true : false) : singleProduct._Id ? true : false;
            const onlineMenuSelection: APIv1.OnlineMenuResponseModel = isEditing && cart.onlineMenu ? cart.onlineMenu : onlineMenu.data;

            if (!onlineMenuSelection) return null;

            let foundInOnlineMenuItemId: number;
            onlineMenuSelection.Pages.forEach((page) => {
                page.Products.forEach((product) => {
                    if (foundInOnlineMenuItemId) return;

                    if (isMenuFlow) {
                        if (product.MenuFlowId === itemId) {
                            foundInOnlineMenuItemId = product.Id;
                        }

                        return;
                    }

                    if (product.ProductId === itemId) {
                        foundInOnlineMenuItemId = product.Id;
                    }
                });
            });

            let foundImage: string = null;

            for (let i = 0, j = images.length; i < j; i++) {
                if (foundImage) break;

                let page = images[i];

                if (page.data) {
                    for (let k = 0, l = page.data.length; k < l; k++) {
                        if (foundImage) break;

                        let img = page.data[k];

                        if (img.ParentId === foundInOnlineMenuItemId && img.ImageUrl) {
                            foundImage = img.ImageUrl;
                        }
                    }
                }
            }

            return foundImage;
        },
    );

export const canPostOnlineOrder = (state: State.IStateShared): boolean => {
    const isMemberValid: boolean = state.members.data !== null || state.members.guestData !== null;
    const isCartValid: boolean = state.cart !== null && (state.cart.itemsSimple.length !== 0 || state.cart.itemsMenuFlow.length !== 0);
    const isOrderDataValid: boolean =
        state.onlineOrder.createRequest.hasSucceeded === false && state.onlineOrder.recalculateRequest.hasSucceeded === true && state.onlineOrder.recalculateRequest.data !== null;

    return isMemberValid && isCartValid && isOrderDataValid;
};

export const canPayForOnlineOrder = (state: State.IStateShared): boolean => {
    const isMemberValid: boolean = state.members.data !== null || state.members.guestData !== null;
    const isCartValid: boolean = state.cart !== null && (state.cart.itemsSimple.length !== 0 || state.cart.itemsMenuFlow.length !== 0);
    const isOrderDataValid: boolean =
        state.onlineOrder.createRequest.hasSucceeded === false && state.onlineOrder.recalculateRequest.hasSucceeded === true && state.onlineOrder.recalculateRequest.data !== null;
    const isPaymentPossible: boolean =
        state.payment.hasSucceeded === false &&
        state.payment.data === null &&
        state.payment.PaymentStepStatus !== 'complete' &&
        state.payment.PaymentStepStatus !== 'payment_status_check';
    const isCreditCardValid: boolean = state.creditCards.activeCardId !== null || state.creditCards.activeCardToken !== null;

    return isCreditCardValid && isMemberValid && isCartValid && isOrderDataValid && isPaymentPossible;
};

export const __DEMO__canPostOnlineOrder = (state): boolean => {
    /* FIX THIS SELECTOR */
    const isMemberValid: boolean = state.members.data !== null || state.members.guestData !== null;
    const isCartValid: boolean = state.cart !== null && (state.cart.itemsSimple.length !== 0 || state.cart.itemsMenuFlow.length !== 0);

    return isMemberValid && isCartValid;
};

export const getCartMenuFlowTotalQuantityForCurrentLocation = (menuFlowId: number) =>
    createSelector(fromCart.getCart, fromCart.getCartMenuFlowQuantityByMenuFlowId(menuFlowId), fromCurrentLocation.getCurrentLocationNo, (cart, total, currentLocation) => {
        if (!cart || !cart.locationNo || !currentLocation || cart.locationNo !== currentLocation) return null;

        return total;
    });

export const getCartSimpleProductTotalQuantityForCurrentLocation = (productId: number) =>
    createSelector(fromCart.getCart, fromCart.getCartSimpleItemQuantity(productId), fromCurrentLocation.getCurrentLocationNo, (cart, total, currentLocation) => {
        if (!cart || !cart.locationNo || !currentLocation || cart.locationNo !== currentLocation) return null;

        return total;
    });

export const isPaymentDisabledForMember = createSelector(
    fromMembers.getMemberState,
    fromMembers.isMemberLoading,
    fromCreditCards.getCardState,
    (membersState, memberLoading, cardsState) =>
        memberLoading ||
        membersState.isDownloading ||
        (membersState.isGuestModeEnabled && !membersState.guestData) ||
        (membersState.isGuestModeEnabled && !cardsState.activeCardToken) ||
        (!membersState.isGuestModeEnabled && !cardsState.activeCardId && !cardsState.activeCardToken) ||
        (!membersState.isGuestModeEnabled && !membersState.data),
);

export const isZeroPaymentsDisabled = createSelector(
    fromCart.getCartTotalQuantity,
    fromCart.getCartTotalValue,
    fromMembers.getMemberState,
    (cartTotalQuantity, cartTotalValue, membersState) => {
        if (cartTotalQuantity && !cartTotalValue) {
            return (membersState.isGuestModeEnabled && !membersState.guestData) || (!membersState.isGuestModeEnabled && !membersState.data);
        }

        return null;
    },
);

export const isZeroPricedOrder = createSelector(
    fromCart.getCartTotalQuantity,
    fromCart.getCartTotalValue,
    (cartTotalQuantity, cartTotalValue) => cartTotalQuantity && !cartTotalValue,
);

export const isPaymentDisabledForAccountCharge = (config: IConfig) =>
    createSelector(
        fromMembers.isAccountSet(config),
        fromCreditCards.getCardState,
        memberHasAvailableBalanceToPayForCartOrder,
        (isAccountSet, cardsState, accountHasSufficientCredits) => {
            if (cardsState.activeCardId !== -1) return false;
            if (!isAccountSet) return true;

            return !accountHasSufficientCredits;
        },
    );

export const isCartLocationsPickupsCalculating = createSelector(
    fromCart.getCartLocationNo,
    fromAvailablePickups.getAvailablePickupTimesForAllLocations,
    (locationNo, availablePickups) => {
        if (!locationNo) return null;
        const foundAvailablePickups = availablePickups.find((item) => item.locationNo === locationNo);

        if (!foundAvailablePickups) return null;

        return foundAvailablePickups.isCalculating;
    },
);

export const getCurrentLocationBusinessModel = createSelector(fromCurrentLocation.getCurrentLocationNo, fromLocations.getLocationsState, (locationNo, locations) => {
    if (!locationNo || !locations || !locations.data) return null;

    const currentLocation = locations.data.find((obj) => obj.LocationNo === locationNo);

    return currentLocation || null;
});

export const getLocationImageForCurrentLocation = createSelector(fromCurrentLocation.getCurrentLocationNo, fromLocationsImages.getAllLocationImages, (locationNo, images) => {
    if (!locationNo || !images || !images.forWeb) return null;
    const foundImage = images.forWeb.find((obj) => obj.Id === locationNo);

    return foundImage && foundImage.data ? foundImage.data.ImageUrl : null;
});

export const getOpenStatusForCurrentLocation = createSelector(
    fromCurrentLocation.getCurrentLocationNo,
    fromLocations.getOrderInfoCompleteForLocationsByDate(),
    (locationNo, orderInfo) => {
        if (!locationNo || !orderInfo) return false;

        const foundStatusObj = orderInfo.find((obj) => obj.locationNo === locationNo && obj.hasSucceeded === true);
        if (!foundStatusObj) return false;

        return foundStatusObj.IsOpen;
    },
);

export const isLocationLoading = (locationNo: number): MemoizedSelector<State.IStateShared, boolean> =>
    createSelector(
        fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo),
        fromLocations.getOrderInfoCompleteForLocationByDate(locationNo),
        (availablePickups, orderInfo) => !availablePickups || availablePickups.isCalculating || orderInfo.isDownloading,
    );

export const isLoadingDataForAnyLocation: MemoizedSelector<State.IStateShared, boolean> = createSelector(
    fromAvailablePickups.isCalculatingAvailablePickups,
    fromLocations.getLocationsState,
    (isCalculating, { isDownloading }) => isCalculating === true || isDownloading === true,
);

export const getCurrentLocationDetails = createSelector(fromCurrentLocation.getCurrentLocationNo, fromLocations.getLocations, (locationNo, locations) => {
    if (!locationNo || !locations) return null;

    return locations.find((obj) => obj.LocationNo === locationNo);
});

export const getPeriodsForCurrentLocation = (format: string = 'ddd, D MMM', prefixes: boolean = true) =>
    createSelector(getCurrentLocationDetails, (location) => {
        if (!location) return null;
        const periods = Utils.Pickups.getFilteredOrderingTimeInfo(location).reduce((acc, obj) => {
            acc.push(Utils.Pickups.createPeriodObject(obj, format, prefixes));

            return acc;
        }, []);

        return periods;
    });

export const getCurrentLocationFuturePickupList = (format: string = 'ddd, D MMM', prefixes: boolean = true) =>
    createSelector(getPeriodsForCurrentLocation(format, prefixes), getCurrentLocationDetails, (periods, location) => {
        if (!periods || !location) return null;

        return periods.reduce(
            (acc, period) => [
                ...acc,
                ...Utils.Pickups.generatePickupTimesFutureList(period, {
                    location,
                    openingHours: location.OrderingTimeInfo,
                }),
            ],
            [] as OLO.Ordering.IPickupTime[],
        );
    });

export const getLocationPeriods = (locationNo: number, format: string = 'ddd, D MMM', prefixes: boolean = true) =>
    createSelector(fromLocations.getLocationDetails(locationNo), fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo), (location, availablePickups) => {
        if (!location || !location.OrderingTimeInfo) return null;

        return Utils.Pickups.getFilteredOrderingTimeInfo(location).reduce((acc, obj) => {
            const period = Utils.Pickups.createPeriodObject(obj, format, prefixes);
            const isToday = Utils.Dates.isToday(obj.Date);

            if (isToday) {
                const todaysList = availablePickups?.data?.filter((a) => a.IsToday === true);
                if (!todaysList || !todaysList?.length) return acc;
            }

            acc.push(period);

            return acc;
        }, []);
    });

export const getLocationFuturePickupList = (config: IConfig, locationNo: number, format: string = 'ddd, D MMM', prefixes: boolean = true) =>
    createSelector(getLocationPeriods(locationNo, format, prefixes), fromLocations.getLocationDetails(locationNo), (periods, location) => {
        if (!periods || !location) return null;

        return periods.reduce(
            (acc, period) => [
                ...acc,
                ...Utils.Pickups.generatePickupTimesFutureList(period, {
                    location,
                    openingHours: location.OrderingTimeInfo,
                    orderTimeoutBufferMins: config.collectionTypes.pickup.orderTimeoutBufferMins,
                    startBufferMins: config.collectionTypes.pickup.startBufferMins,
                    nextTick: config.collectionTypes.pickup.nextTick,
                }),
            ],
            [] as OLO.Ordering.IPickupTime[],
        );
    });

export const getAvailablePickupTimesWithFutureForLocation = (
    config: IConfig,
    locationNo: number,
    futureOrders: boolean = false,
): MemoizedSelector<State.IStateShared, OLO.Ordering.IPickupTime[]> =>
    createSelector(
        fromCurrentLocation.getCurrentPickupTime,
        fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo),
        getLocationFuturePickupList(config, locationNo),
        (pickupTime, availablePickups, futurePickupList) => {
            let arr: OLO.Ordering.IPickupTime[] = [];
            if (!locationNo) return arr;

            if (availablePickups.data) {
                arr = [...availablePickups.data];
            }

            if (futureOrders && futurePickupList) {
                futurePickupList.forEach((pickup) => {
                    if (arr.some((obj) => obj.Id === pickup.Id)) return;

                    arr.push(pickup);
                });
            }

            return arr;
        },
    );

export const getFilteredLocations = (
    sortTag: string = null,
    filterParams: OLO.Common.ILocationFilterParams = {},
    config: IConfig,
): MemoizedSelector<State.IStateShared, APIv1.LocationBusinessModel[]> =>
    createSelector(
        fromLocations.getLocationsState,
        fromLocationsFilters.getLocationFilters,
        fromAvailablePickups.getAvailablePickupTimesForAllLocations,
        fromGeolocation.getGeolocationState,
        (locations, filters, availablePickups, geoLocationState) => {
            if (!locations.data.length || locations.isDownloading || locations.hasFailed) return [];

            const filterBy: OLO.Common.ILocationFilterParams = {
                bySearch: true,
                byOpenStatus: false,
                byPickupTime: false,
                byDeliveryRadius: true,
                ...filterParams,
            };

            let clientTime: Date | string = new Date();
            const isFutureSearch = filters.pickupTime ? filters.pickupTime.IsToday === false : false;
            const filtered = locations.data.filter((location) => {
                if (!location.LocationFriendlyName || !location.OrderTypes.length || !location.LocationOLOIsActive || location.EOIPriceLevelID === 0) return false;

                const collectionTypesInfo = new Utils.LocationCollectionTypesChecker(location, config);
                if (!collectionTypesInfo.hasAnyTypes()) {
                    return false;
                }

                const l = locations.data.find((obj) => obj.LocationNo === location.LocationNo);

                const orderInfoForLocation = Utils.Pickups.getFilteredOrderingTimeInfo(l);
                if (!orderInfoForLocation) return false;

                const dateToSearch = (filters.pickupTime && filters.pickupTime.DateLocalISO) || Utils.Dates.getLocalISOFormatDate(new Date());
                let timeInfo: APIv1.LocationOrderingTimeInfoModel = orderInfoForLocation.find(Utils.Pickups.datesMatchByDayCallback(dateToSearch));

                const isLocationConfiguredForFutureOrder = location.FutureOrderingMinDaysAhead !== null && location.FutureOrderingMaxDaysAhead !== null;

                if (!timeInfo && filterBy.byPickupTime) return false;

                /* Check hours - if no filters - compare to current time, else, get time from filters */
                if (filterBy.byPickupTime && filters.pickupTime) {
                    clientTime = filters.pickupTime.DateLocalISO;
                }

                const locationPickupTimesObj: State.IAvailablePickup = availablePickups.find(
                    (obj) => obj.locationNo === location.LocationNo && obj.hasSucceeded === true && obj.data.length > 0,
                );
                const locationPickupTimesList: OLO.Ordering.IPickupTime[] = locationPickupTimesObj ? locationPickupTimesObj.data : [];
                const locationAsapObj: OLO.Ordering.IPickupTime = locationPickupTimesList[0] || null;
                const openTime: number = timeInfo ? Utils.Dates.createHoursIntFromDate(timeInfo.OpeningTime) : null;
                const closeTime: number = timeInfo ? Utils.Dates.createHoursIntFromDate(timeInfo.ClosingTime) : null;

                /* AOLO-273 - ASAP - if location is still open and preperation doesn't exceed location open time */
                if (filterBy.byPickupTime) {
                    if (!filters.pickupTime || filters.pickupTime.IsAsap) {
                        if (locationAsapObj) {
                            const preperationTime: number = Utils.Dates.createHoursIntFromDate(locationAsapObj.Id + locationAsapObj.MinutesFromNow * 60 * 1000);

                            if (preperationTime >= closeTime) return false;
                        }
                    }

                    /* AOLO-276 - validate open status when filters is set to ASAP */
                    if (!filters.pickupTime || filters.pickupTime.IsAsap) {
                        const isLocationOpen: boolean = Utils.Dates.isHourInHoursRange(
                            Utils.Dates.getLocalISOFormatDate(new Date()),
                            timeInfo.OpeningTime,
                            timeInfo.ClosingTime,
                            'from',
                        );

                        if (!isLocationOpen) {
                            return false;
                        }
                    }
                }

                if (filterBy.byOpenStatus) {
                    const timeToCheck: number = Utils.Dates.createHoursIntFromDate(clientTime);

                    if (timeToCheck < openTime || timeToCheck > closeTime) {
                        return false;
                    }
                }

                if (filterBy.bySearch) {
                    if (filters.geocoder) {
                        let isMetric = config.localization?.units === 'metric';
                        let radius = isMetric ? config.google?.maps?.searchDistance : config.google?.maps?.searchDistance * 1.609344 || null;

                        if (!radius || !location?.Latitude || !location?.Longitude) {
                            return false;
                        }

                        const distance = Utils.Geolocation.getDistanceFromTo(
                            {
                                latitude: filters.geocoder.lat,
                                longitude: filters.geocoder.lng,
                            },
                            {
                                latitude: location.Latitude,
                                longitude: location.Longitude,
                            },
                        );

                        location.Distance = distance;

                        return distance <= radius;

                    } else {
                        let searchMatch: boolean = true;
                        if (filters.search) {
                            const searchString: string = filters.search.toLowerCase();
                            const _s = (fieldName: string) => (location[fieldName] !== null ? location[fieldName].toLowerCase().includes(searchString) : false);

                            searchMatch = _s('LocationFriendlyName') || _s('LocationDescription') || _s('StreetAddress') || _s('Suburb') || _s('PostCode') || _s('LocationNotes');
                        }
                        if (!searchMatch) return false;
                    }
                }

                if (filterBy.byDeliveryRadius) {
                    const radius = config.collectionTypes?.delivery?.radius || null;

                    if (!radius || !filters?.address?.geometry || !location?.Latitude || !location?.Longitude) {
                        return false;
                    }

                    const distance = Utils.Geolocation.getDistanceFromTo(
                        {
                            latitude: filters.address.geometry.lat,
                            longitude: filters.address.geometry.lng,
                        },
                        {
                            latitude: location.Latitude,
                            longitude: location.Longitude,
                        },
                    );

                    location.Distance = distance;

                    return distance <= radius;
                }

                if (filterBy.byPickupTime) {
                    /* Validate filter pickup time */
                    const availablePickup = availablePickups.find((pickup) => pickup.locationNo === location.LocationNo && pickup.hasSucceeded === true && pickup.data !== null);
                    if (!isFutureSearch && (!availablePickup || !availablePickup.data || availablePickup.data.length === 0)) {
                        return false;
                    }

                    if (!isFutureSearch && !isLocationConfiguredForFutureOrder) {
                        if (!filters.pickupTime) return true; /* Pure ASAP */

                        return availablePickup.data.some((pickup) => pickup.Id === filters.pickupTime.Id);
                    }

                    const isOk = Utils.Pickups.isFuturePickupTimeValid(
                        filters.pickupTime || null,
                        timeInfo,
                        location.FutureOrderingMinDaysAhead,
                        location.FutureOrderingMaxDaysAhead,
                    );

                    return isOk;
                }

                return true;
            });

            const sortFilteredLocation = (a, b, nameOfProperty: string) => {
                const aD = a[nameOfProperty];
                const bD = b[nameOfProperty];

                switch (true) {
                    case aD === bD:
                        return 0;
                    case aD < bD:
                        return -1;
                    case aD > bD:
                        return 1;
                }
            };

            const sortFunc = (a, b) => {
                const aTag = !!a.LocationTags.find((tag) => tag.TagName === sortTag);
                const bTag = !!b.LocationTags.find((tag) => tag.TagName === sortTag);

                switch (true) {
                    case aTag && bTag:
                        return 0;
                    case aTag && !bTag:
                        return -1;
                    case !aTag && bTag:
                        return 1;
                }
            };

            if (!sortTag || !filters.geocoder) {
                if (geoLocationState.data) {
                    const serchedLocationsId = filtered.map(l => l.LocationNo);

                    const returnLocations = filtered
                        .map(location => {
                            const Distance = Utils.Geolocation.getDistanceFromTo({
                                latitude: geoLocationState.data.latitude,
                                longitude: geoLocationState.data.longitude,
                            }, {
                                latitude: location.Latitude,
                                longitude: location.Longitude,
                            });

                            return { ...location, Distance };
                        });

                    locations.data.forEach(notFilteredLocation => {
                        if (!serchedLocationsId.includes(notFilteredLocation.LocationNo)) {
                            filtered.forEach(filteredLoc => {
                                const Distance2Location = Utils.Geolocation.getDistanceFromTo({
                                    latitude: filteredLoc.Latitude,
                                    longitude: filteredLoc.Longitude,
                                }, {
                                    latitude: notFilteredLocation.Latitude,
                                    longitude: notFilteredLocation.Longitude,
                                });

                                const returnLocationsIds = returnLocations.map(loc => loc.LocationNo);

                                if (config.google?.maps?.searchDistance >= (config.localization.units === 'imperial' ?
                                    Utils.Numbers.kilometersToMiles(Distance2Location) :
                                    Distance2Location)
                                    && !returnLocationsIds.includes(notFilteredLocation.LocationNo)) {

                                    const DistanceToUser = Utils.Geolocation.getDistanceFromTo({
                                        latitude: geoLocationState.data.latitude,
                                        longitude: geoLocationState.data.longitude,
                                    }, {
                                        latitude: notFilteredLocation.Latitude,
                                        longitude: notFilteredLocation.Longitude,
                                    });

                                    returnLocations.push({ ...notFilteredLocation, Distance: DistanceToUser });
                                }
                            });
                        }
                    });

                    return returnLocations
                        .sort((a, b) => sortFilteredLocation(a, b, 'Distance'))
                        .map(location => {
                            delete location.Distance;

                            return { ...location };
                        });
                } else {
                    return filtered
                        .sort((a, b) => sortFilteredLocation(a, b, 'LocationFriendlyName'));
                }
            } else {
                if (sortTag === 'Distance') {
                    filtered.sort((a, b) => sortFilteredLocation(a, b, 'Distance'))
                        .map(location => {
                            delete location.Distance;

                            return { ...location };
                        });
                }

                return filtered.sort(sortFunc);
            }
        },
    );

export const getState = (state: State.IStateShared): State.IStateShared => state;

export const getMenuFlowDetailsByWizzard = (state: State.IStateShared): APIv1.MenuFlowDetailsModel => {
    if (!state.wizzard.itemsMenuFlow) return null;
    const { MenuFlowId, LocationNo } = state.wizzard.itemsMenuFlow;
    const menuFlow = state.menuFlows.find((menuFlowObj) => menuFlowObj.MenuFlowId === MenuFlowId && menuFlowObj.LocationNo === menuFlowObj.LocationNo);

    return menuFlow ? menuFlow.data : null;
};

/* Get price for product in menuflow including special price conditions */
export const getNextPriceForMenuFlowProduct = (productId: number, pageIdentifier: number): MemoizedSelector<State.IStateShared, number> =>
    createSelector(getMenuFlowDetailsByWizzard, fromWizzard.getWizzardMenuFlowPageProducts(pageIdentifier), (menuFlow, wizzardPageProducts) => {
        /* TODO: move this logic to utils Pricing */
        if (!menuFlow) return null;

        const pageDetails: APIv1.MenuFlowPage = menuFlow.Pages.find((Page) => Page.PageIdentifier === pageIdentifier);
        if (!pageDetails) return null;

        const product: APIv1.MenuFlowProduct = pageDetails.Products.find((productItem) => productItem.ProductId === productId);
        const extra: APICommon.IIngredientModifierExtended =
            wizzardPageProducts
                .find(productItem => productItem.ProductId === product.ProductId)?.IngredientsChanges?.IngredientsModified[0];

        const addExtra = (price) => (extra && extra.ExtraPrice) ? price + extra.ExtraPrice : price;

        if (!product) return null;

        if (product.ExcludeFromSpecialPricing) {
            return product['OverridedPrice'] !== null ? addExtra(product['OverridedPrice']) : addExtra(product['OriginalPrice']);
        }

        const sortDetails = Utils.Pricing.sortDetails(pageDetails);
        if (sortDetails.property === 'default') {
            if (menuFlow.OverridePrice !== null) {
                return addExtra(product.OverridedPrice) || 0;
            } else {
                return product.OverridedPrice !== null ? addExtra(product.OverridedPrice) : addExtra(product.OriginalPrice);
            }
        }

        const isPageSpecialPricingLimitReached: boolean = Utils.Pricing.isPageSpecialPricingLimitReached(pageDetails, wizzardPageProducts as APIv1.MenuFlowProduct[]);

        if (isPageSpecialPricingLimitReached) {
            return product[sortDetails.property] !== null ? addExtra(product[sortDetails.property]) : addExtra(product['OriginalPrice']);
        }

        return addExtra(pageDetails.SpecialPrice);
    });

/* Get modal data for menu flow */
export const getModalDataForOnlineMenuItem = (
    itemId: number,
    isMenuFlow: boolean,
): MemoizedSelector<State.IStateShared, OLO.Components.Modals.IModalDynamicStateForOnlineMenuItem<State.IMenuFlowImage>> =>
    createSelector(
        fromOnlineMenu.getOnlineMenuItemById(itemId),
        fromCart.getCartOnlineMenuItemById(itemId),
        fromMenuFlowImages.getImageForMenuFlow(itemId),
        fromWizzard.getWizzard,
        (onlineMenu, cartOnlineMenu, image, wizzard) => {
            if (!wizzard || (isMenuFlow && !wizzard.itemsMenuFlow) || (!isMenuFlow && !wizzard.itemsSimple)) return null;

            const isEditing = isMenuFlow ? !!wizzard.itemsMenuFlow['_Id'] : !!wizzard.itemsSimple['_Id'];

            if ((!isEditing && !onlineMenu) || (isEditing && !cartOnlineMenu)) return null;

            const selectedOnlineMenu: APIv1.OnlineMenuProductResponseModel = isEditing ? cartOnlineMenu : onlineMenu;

            return {
                PosDisplay: selectedOnlineMenu.PosDisplay,
                PosDescription: selectedOnlineMenu.PosDescription,
                Image: isMenuFlow ? image : null,
                Stats: {
                    price: selectedOnlineMenu.Price,
                    cals: selectedOnlineMenu.Kilojoules,
                },
            };
        },
    );

export const getStatsForSingleProduct = (productId: number): MemoizedSelector<State.IStateShared, OLO.Components.IStatsComponentInput> =>
    createSelector(fromOnlineMenu.getOnlineMenuItemById(productId), (product) => {
        if (!product) return null;

        return {
            price: product.Price,
            kilojoules: product.Kilojoules,
            cals: Math.ceil(product.Kilojoules / 4.184),
        };
    });

export const getMenuFlowDetailsSepcificPageByWizzard = (pageIdentifier: number): MemoizedSelector<State.IStateShared, APIv1.MenuFlowPage> =>
    createSelector(getMenuFlowDetailsByWizzard, (menuFlow) => {
        if (!menuFlow) return null;

        return menuFlow.Pages.find((page) => page.PageIdentifier === pageIdentifier);
    });

export const getMenuFlowDetailsPagesByWizzard = createSelector(getMenuFlowDetailsByWizzard, (menuFlow) => {
    if (!menuFlow) return null;

    return menuFlow.Pages;
});

export const isWizzardPageLimitReached = (pageIdentifier: number): MemoizedSelector<State.IStateShared, boolean> =>
    createSelector(getMenuFlowDetailsSepcificPageByWizzard(pageIdentifier), fromWizzard.getWizzardMenuFlowPageItemsTotalQuantity(pageIdentifier), (page, currentTotalQuantity) => {
        if (!page || !Number.isInteger(currentTotalQuantity)) return null;

        return currentTotalQuantity >= page.PageMaxQuantity;
    });

export const isWizzardPageOneAllowed = (pageIdentifier: number): MemoizedSelector<State.IStateShared, boolean> =>
    createSelector(getMenuFlowDetailsSepcificPageByWizzard(pageIdentifier), (pageDetails) => (pageDetails ? pageDetails.PageMaxQuantity === 1 : null));

export const getCurrentLocationNoWithMemberId: MemoizedSelector<State.IStateShared, { locationNo: number; memberId: number; }> =
    createSelector(fromCurrentLocation.getCurrentLocationNo, fromMembers.getCurrentMember, (locationNo, member) => ({
        locationNo,
        memberId: member ? member.MemberId : null,
    }));

export const getCartMenuFlowDescription = (tempId: number): MemoizedSelector<State.IStateShared, string> =>
    createSelector(fromCart.getCartMenuFlowById(tempId), (menuFlow) => {
        if (!menuFlow) return null;

        return Utils.Items.generateCartMenuFlowDescription(menuFlow);
    });

export const getCartMenuFlowSpecialInstructions = (tempId: number): MemoizedSelector<State.IStateShared, string> =>
    createSelector(fromCart.getCartMenuFlowById(tempId), (menuFlow) => {
        if (!menuFlow) return null;

        return Utils.Items.generateCartMenuSpecialInstructions(menuFlow);
    });

export const getCartSimpleProductSpecialInstructions = (tempId: number): MemoizedSelector<State.IStateShared, string> =>
    createSelector(fromCart.getCartSimpleItemById(tempId), (simpleProduct) => {
        if (!simpleProduct) return null;

        return Utils.Items.generateCartMenuSpecialInstructions(simpleProduct);
    });

export const getCartLocationFriendlyName: MemoizedSelector<State.IStateShared, string> = createSelector(fromCart.getCart, fromLocations.getLocations, (cart, locations) => {
    if (!cart || !cart.locationNo || !locations) return null;
    const foundLocation = locations.find((location) => location.LocationNo === cart.locationNo);

    return foundLocation ? foundLocation.LocationFriendlyName : null;
});

export const routeIsLocationDetailsPage = (config: IConfig, LOCATION_DETAILS_REGEXP: RegExp = /\/locations\/\d+\/details\??/gim) =>
    createSelector(fromRouter.getCurrentRoute, fromAppSettings.getAppLocationMode, (route, locationMode) => {
        if (route === null || locationMode === null) return null;

        if (locationMode === OLO.Enums.APP_MODE.LOCATION) {
            const matchFound: RegExpMatchArray = route.url.match(LOCATION_DETAILS_REGEXP);

            return !!matchFound;
        }

        return route.params.LocationFriendlyName !== undefined && route.url ? route.url.includes(config.venue.name) : false;
    });

export const getActiveLocationFriendlyNameFromCartOrCurrentLocation = (config: IConfig): MemoizedSelector<State.IStateShared, string> =>
    createSelector(getCartLocationFriendlyName, getCurrentLocationDetails, routeIsLocationDetailsPage(config), (nameFromCart, currentLocation, isLocationDetailsPage) => {
        if (nameFromCart) return nameFromCart;
        if (!isLocationDetailsPage || !currentLocation) return null;

        return currentLocation.LocationFriendlyName;
    });

export const canNavigateToCheckoutAuthorizedOnly: MemoizedSelector<State.IStateShared, boolean> = createSelector(
    fromCart.isCartEmpty,
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentPickupTime,
    fromMembers.isGuestModeEnabled,
    fromMembers.isMemberAuthorizedJWT,
    (isCartEmpty, currentLocationNo, pickupTime, isGuestModeEnabled, isMemberAuthorizedJWT) => {
        if (!isGuestModeEnabled && !isMemberAuthorizedJWT) {
            return false;
        }

        if (isCartEmpty === true) {
            return false;
        }

        if (currentLocationNo === null || currentLocationNo === 0) {
            return false;
        }

        if (pickupTime === null) {
            return false;
        }

        return true;
    },
);

export const canNavigateToCheckoutUnauthorized: MemoizedSelector<State.IStateShared, boolean> = createSelector(
    fromCart.isCartEmpty,
    fromCurrentLocation.getCurrentLocationNo,
    fromCurrentLocation.getCurrentPickupTime,
    (isCartEmpty, currentLocationNo, pickupTime) => {
        if (isCartEmpty === true) {
            return false;
        }

        if (currentLocationNo === null || currentLocationNo === 0) {
            return false;
        }

        if (pickupTime === null) {
            return false;
        }

        return true;
    },
);

export const isMenuFlowProductSelectable = (pageIdentifier: number, productId: number, productState: number = null) =>
    createSelector(
        isWizzardPageOneAllowed(pageIdentifier),
        isWizzardPageLimitReached(pageIdentifier),
        fromWizzard.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId),
        (isPageOneAllowed, pageLimitReached, productQty) => {
            if (isPageOneAllowed) return true;
            const isClickable: boolean = productQty === 0 && pageLimitReached === false;

            return productState === null ? isClickable : isClickable && productState === 0;
        },
    );

export const productActionToTake = (pageIdentifier: number, productId: number) =>
    createSelector(
        isMenuFlowProductSelectable(pageIdentifier, productId),
        isWizzardPageLimitReached(pageIdentifier),
        fromWizzard.getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId),
        isWizzardPageOneAllowed(pageIdentifier),
        (canSelect, pageLimitReached, productQty, isPageOneAllowed) => {
            if (!canSelect) return null;
            if (isPageOneAllowed) {
                if (pageLimitReached && productQty !== 0) {
                    return 'remove';
                }

                if (pageLimitReached && productQty === 0) {
                    return 'replace';
                }

                if (!pageLimitReached) {
                    return 'add';
                }
            } else {
                if (!pageLimitReached) {
                    return 'add';
                }

                return null;
            }
        },
    );

export const isPaymentProcessValid = createSelector(fromPayment.getPaymentState, fromOnlineOrder.getOnlineOrderState, (payment, onlineOrder) => {
    if (payment.Errors.length > 0) return false;
    if (!onlineOrder.recalculateRequest.data) return false;

    return true;
});

export const routeIsLocationsSearchPage = (config: IConfig) =>
    createSelector(fromRouter.getCurrentRoute, fromAppSettings.getAppLocationMode, (route, locationMode) => {
        if (route === null || locationMode === null) return null;
        if (locationMode === OLO.Enums.APP_MODE.LOCATION) {
            return route.url === '/locations';
        }

        return route.url === `/${config.venue.name}`;
    });

export const routeIsAccountPage = (ACCOUNT_REGEXP: RegExp = /\/account(\/[a-zA-Z/]{1,})?$/i) =>
    createSelector(fromRouter.getCurrentRoute, (route) => (route === null ? null : route.url.match(ACCOUNT_REGEXP) !== null ? true : false));

export const routeIsErrorPage = (ERROR_REGEXP: RegExp = /\/404$/i) =>
    createSelector(fromRouter.getCurrentRoute, (route) => (route === null ? null : route.url.match(ERROR_REGEXP) !== null ? true : false));

export const getOrderErrorsMapped = createSelector(
    fromAppSettings.isOnlineMapped,
    fromPayment.getPaymentErrorsMapped,
    fromOnlineOrder.onlineOrderErrorsMapped,
    (offlineErrors, paymentErrors, onlineOrders) => offlineErrors || paymentErrors || onlineOrders,
);

export const getOrderingTimeInfoForCartPickupLocationNo = (locationNo: number) =>
    createSelector(fromCart.getCart, fromLocations.getLocationDetails(locationNo), (cart, locations) => {
        const timeinfo = locations?.OrderingTimeInfo;
        if (!timeinfo) return null;

        const isValid = timeinfo.find(Utils.Pickups.datesMatchByDayCallback(cart.pickupTime.DateLocalISO));

        return isValid;
    });

export const generatedPickupTimesForLocationForPeriod = (
    period: OLO.Ordering.IPeriod,
    locationNo: number,
    orderTimeoutBufferMins: number = 0,
    startBufferMins: number = 60,
    nextTick: number = 60,
) =>
    createSelector(
        fromLocations.getPickupTimesForSelectedDateForLocation(period.Date, locationNo),
        fromAvailablePickups.getAvailablePickupTimesForLocation(locationNo),
        (timeInfo, todayPickups) => {
            if (!period || period.IsToday) return todayPickups.data || null;

            return Utils.Pickups.generatePickupTimesFutureList(period, {
                location: { LocationNo: locationNo },
                asapPickupMins: nextTick,
                openingHours: [
                    {
                        Date: period.Date,
                        ...timeInfo,
                    },
                ],
                orderTimeoutBufferMins,
                startBufferMins,
                nextTick,
            });
        },
    );

export const canOrderFromLocation2 = (locationNo: number, config: IConfig) =>
    createSelector(
        fromLocations.getLocationDetails(locationNo),
        fromLocations.getMinimumPickupTimeForLocation(locationNo),
        fromLocations.getOrderingTimeInfoByLocationNo(locationNo),
        fromLocations.isLocationAvailableForOnlineOrdering(locationNo),
        fromLocations.getLocationOpenStatus(locationNo),
        fromCurrentLocation.getCurrentPickupTime,
        (location, minimumPickupTime, openingHours, isAvailable, isOpen, currentPickupTime) => {
            if (!location || !locationNo || !minimumPickupTime || minimumPickupTime.hasSucceeded === false || !openingHours || isAvailable === null) return null;

            if (!isAvailable || (!config.onlineOrders.allowClosedLocationOrders && !isOpen && config.futureOrders !== true)) {
                return false;
            }

            return Utils.OnlineOrders.canOrder(
                location,
                config.futureOrders === true,
                minimumPickupTime.MinimumPickupTime,
                openingHours,
                config.collectionTypes?.pickup?.orderTimeoutBufferMins,
                config.collectionTypes?.pickup?.startBufferMins,
            );
        },
    );

export const canOrderFromLocation = (locationNo: number, config: IConfig) =>
    createSelector(
        getLocationFuturePickupList(config, locationNo),
        fromLocations.getLocationDetails(locationNo),
        fromLocations.getMinimumPickupTimeForLocation(locationNo),
        fromLocations.getLocationOpenStatus(locationNo),
        fromLocationsFilters.getLocationFilters,
        fromAppSettings.getAppLocationMode,
        (availablePickups, location, minPickupTime, isOpen, filters, locationMode) => {
            if (!availablePickups || availablePickups.length === 0 || !location || location.OnlineOrderingStatus !== 0) return false;

            const collectionTypesCheck = new Utils.LocationCollectionTypesChecker(location, config);
            if (!collectionTypesCheck.hasAnyTypes()) return false;

            const canOrderFromClosedLocation = config.futureOrders === true || config.onlineOrders.allowClosedLocationOrders === true;
            const isClosed = !canOrderFromClosedLocation && !isOpen;
            if (isClosed) return false;

            const locationIsConfiguredForFutureOrdering =
                location.FutureOrderingMaxDaysAhead !== null && location.FutureOrderingMinDaysAhead !== null && location.FutureOrderingMaxDaysAhead !== 0;

            if (!locationIsConfiguredForFutureOrdering) {
                return Utils.OnlineOrders.canOrder(
                    location,
                    false,
                    minPickupTime.MinimumPickupTime,
                    location.OrderingTimeInfo,
                    config.collectionTypes?.pickup?.orderTimeoutBufferMins,
                    config.collectionTypes?.pickup?.startBufferMins,
                );
            }

            if (locationMode === OLO.Enums.APP_MODE.LOCATION) return true;

            if (config.futureOrders === true) {
                const isToday = Utils.Dates.isToday(filters.pickupTime?.DateLocalISO || new Date());

                if (!isToday) {
                    return Utils.Pickups.isDateInFutureOrdersTimeRange(
                        filters.pickupTime?.DateLocalISO || new Date(),
                        location.FutureOrderingMinDaysAhead,
                        location.FutureOrderingMaxDaysAhead,
                    );
                }
            }

            return Utils.OnlineOrders.canOrder(
                location,
                config.futureOrders === true,
                minPickupTime.MinimumPickupTime,
                location.OrderingTimeInfo,
                config.collectionTypes?.pickup?.orderTimeoutBufferMins,
                config.collectionTypes?.pickup?.startBufferMins,
            );
        },
    );

export const showCollectionTypeToggleForHomePage = (config: IConfig) =>
    createSelector(fromCollectionType.getCollectionTypesList(config), fromRouter.isCurrentRouteHome, fromRouter.isCurrentRouteLoyalty, (list, isHome, isLoyaltyHome) => {
        if ((config.venue?.id && config.venue?.name) || (!isHome && !isLoyaltyHome) || !list.length) return false;

        return list?.length > 1;
    });

export const getCollectionTypesListForLocation = (locationNo: number, config: IConfig) =>
    createSelector(fromLocations.getLocationDetails(locationNo), fromCollectionType.getCollectionType, (location, collectionType) => {
        let collectionTypes: Models.CollectionTypeItem[] = [];
        if (!location) return null;
        const LocationColletionTypesChecker = new Utils.LocationCollectionTypesChecker(location, config);

        if (LocationColletionTypesChecker.hasPickups()) {
            const isPickupActive: boolean =
                LocationColletionTypesChecker.getPickupIds().includes(collectionType?.orderTypeId) || collectionType.orderTypeId === OLO.Enums.COLLECTION_TYPE.PICKUP;
            collectionTypes.push(
                new Models.CollectionTypeItem(collectionTypes.length + 1, 'Pick up', OLO.Enums.COLLECTION_TYPE.PICKUP, isPickupActive, OLO.Enums.COLLECTION_TYPE.PICKUP),
            );
        }

        if (LocationColletionTypesChecker.hasDelivery()) {
            const deliveryOrderTypeId = LocationColletionTypesChecker.getDeliveryIds()[0];
            collectionTypes.push(
                new Models.CollectionTypeItem(
                    collectionTypes.length + 1,
                    'Delivery',
                    deliveryOrderTypeId,
                    collectionType?.orderTypeId === deliveryOrderTypeId,
                    OLO.Enums.COLLECTION_TYPE.DELIVERY,
                ),
            );
        }

        const now = Utils.Dates.getLocalISOFormatDate(new Date());
        const foundTodaysOrderingTimeInfoObj = location.OrderingTimeInfo.find((obj) => obj.Date.split('T')[0] === now.split('T')[0]);
        const isOpenToday = foundTodaysOrderingTimeInfoObj ? new Utils.LocatioOpenStatus(foundTodaysOrderingTimeInfoObj).isOpenForProvidedDate(now) : false;
        if (isOpenToday && LocationColletionTypesChecker.hasDineIn()) {
            const dineInOrderTypeId = LocationColletionTypesChecker.getDineInIds()[0];
            collectionTypes.push(
                new Models.CollectionTypeItem(
                    collectionTypes.length + 1,
                    'Dine in',
                    dineInOrderTypeId,
                    collectionType?.orderTypeId === dineInOrderTypeId,
                    OLO.Enums.COLLECTION_TYPE.DINE_IN,
                ),
            );
        }

        return collectionTypes;
    });

export const getOrderTypeDescriptionForOrder = (orderId: number, locationNo: number) =>
    createSelector(fromHistoryOrders.getHistoryOrder(orderId), fromLocations.getLocationDetails(locationNo), (order, location) => {
        if (!order || !order.data || !location) return null;
        let description: string = 'Pick up';

        const foundLocationOrderType = location.OrderTypes.find((obj) => obj.Id === order.data.OrderTypeId);
        if (foundLocationOrderType) {
            description = foundLocationOrderType.TypeDescription;
        }

        return description;
    });

export const getFilteredOrderTypesForCartsLocationByGroup = (config: IConfig) =>
    createSelector(getOrderTypesForCartsLocation, fromCollectionType.getCollectionType, (orderTypes, collection) => {
        if (!orderTypes) {
            orderTypes = [];
        }
        const _groupDetectorFactory = (orderTypeId: number): Utils.CollectionTypeGroupDetector => new Utils.CollectionTypeGroupDetector(orderTypeId, config);

        const collectionTypeGroup: Utils.CollectionTypeGroupDetector = _groupDetectorFactory(collection?.orderTypeId);
        let orderTypesFilteredByGroup: APIv1.OrderType[] = orderTypes.filter((orderType) => {
            const orderTypeGroup: Utils.CollectionTypeGroupDetector = _groupDetectorFactory(orderType?.Id);

            return orderTypeGroup.getCollectionType() === collectionTypeGroup.getCollectionType();
        });

        if (orderTypesFilteredByGroup.length === 1) {
            orderTypesFilteredByGroup = orderTypesFilteredByGroup.filter((obj) => obj.Details.length > 0);
        }

        return orderTypesFilteredByGroup.map((obj) => ({
            Id: obj.Id,
            Name: obj.Description,
            ...obj,
        }));
    });

export const getDefaultOrderTypeForLocation = (locationNo: number, config: IConfig) => createSelector(
    fromOrderTypes.isDownloadingAnyOrderTypes,
    fromOrderTypes.getOrderTypesForLocation(locationNo),
    fromCollectionType.getCollectionType,
    fromCollectionType.getSelectedCollectionTypeGroupId(config),
    fromLocations.getOrderingTimeInfoByLocationNo(locationNo),
    (isDownloading, orderTypes, collectionType, collectionTypeGroupId, orderingTimeInfo) => {

        if (isDownloading || !orderTypes?.data || !collectionType || collectionTypeGroupId === null || !orderingTimeInfo) return null;

        let foundOrderType: APIv1.OrderType;

        if (collectionType?.orderTypeId) {
            foundOrderType = orderTypes?.data?.find(obj => obj.Id === collectionType.orderTypeId);
        }

        if (foundOrderType) return foundOrderType;

        const isLocationOpen = new Utils.LocatioOpenStatus(orderingTimeInfo[0])
            .isOpenForProvidedDate(Utils.Dates.getLocalISOFormatDate(new Date()));
        let firstAvailableOrderType: APIv1.OrderType;

        orderTypes?.data?.forEach(orderType => {
            if (firstAvailableOrderType) return;
            const orderTypeCheck = new Utils.CollectionTypeGroupDetector(orderType.Id, config);
            if (orderTypeCheck.isDineIn() && !isLocationOpen) {
                return;
            }

            firstAvailableOrderType = orderType;
        });

        if (collectionTypeGroupId) {
            foundOrderType = orderTypes?.data?.find(obj => new Utils.CollectionTypeGroupDetector(obj.Id, config).getCollectionType() === collectionTypeGroupId);
        }

        return foundOrderType || firstAvailableOrderType || null;
    }
);

export const restrictionForLocationOrderType = (locationNo: number, config: IConfig) => createSelector(
    getDefaultOrderTypeForLocation(locationNo, config),
    fromProductRestrictions.getProductRestrictions,
    (orderType, restrictions) => {
        if (!orderType
            || orderType?.IsProductsRestrictionEnabled !== true
            || !restrictions.data
            || restrictions.LocationNo !== locationNo
            || restrictions.isDownloading === true
        ) return null;

        return restrictions.data;
    }
);

export const restrictionsLimit = (locationNo: number) => createSelector(
    fromCart.getCart,
    fromOnlineOrder.getSelectedOrderType,
    fromProductRestrictions.getProductRestrictions,
    fromOrderTypes.getOrderTypesForLocation(locationNo),
    (cart, orderType, productRestrictions, LocationTypes) => {

        if ((!orderType
            || orderType?.IsProductsRestrictionEnabled !== true)
            && !LocationTypes?.data?.length
            || productRestrictions.isDownloading
            || !productRestrictions.data
            || productRestrictions.LocationNo !== cart.locationNo
        ) return null;

        const restrictions: Models.RestrictionItem[] = [];

        const _matchHelper = (
            product: State.ICartSimpleItemExtended | State.ICartMenuFlowPageProduct,
            catIds: number[],
            famIds: number[],
            prodIds: number[]
        ): boolean | number[] => {
            const matchCategoryId = catIds.includes(product.ProductCategoryId);
            const matchFamilyIds = Utils.Arrays.findCommonItems(famIds, product.ProductFamilyIds || []);
            const matchProdId = prodIds.includes(product.ProductId);

            return matchCategoryId || matchFamilyIds || matchProdId;
        };

        productRestrictions.data.forEach(restrictionObj => {
            const catIds = restrictionObj.CategoryIds;
            const famIds = restrictionObj.FamilyIds;
            const prodIds = restrictionObj.ProductIds;

            const simpleProducts: State.ICartSimpleItemExtended[] = [];
            cart.itemsSimple.forEach(simpleProduct => {
                if (_matchHelper(simpleProduct, catIds, famIds, prodIds)) {
                    simpleProducts.push({
                        ...simpleProduct
                    });
                }
            });

            const menuFlowProducts: State.ICartMenuFlowPageProduct[] = [];
            cart.itemsMenuFlow.forEach(menuFlow => {
                menuFlow.Pages.forEach(page => {
                    page.Products.forEach(menuFlowProduct => {
                        if (_matchHelper(menuFlowProduct, catIds, famIds, prodIds)) {
                            menuFlowProducts.push({
                                ...menuFlowProduct,
                                Quantity: menuFlowProduct.Quantity * menuFlow.Quantity
                            });
                        }
                    });
                });
            });

            restrictions.push(new Models.RestrictionItem(
                restrictionObj.CustomerFriendlyName,
                restrictionObj.CustomerFriendlyDescription,
                restrictionObj.CategoryIds,
                restrictionObj.FamilyIds,
                restrictionObj.MaxQty,
                simpleProducts,
                menuFlowProducts
            ));
        });

        return restrictions;
    }
);

export const hasExceededProductsLimit = (locationNo: number) => createSelector(
    restrictionsLimit(locationNo),
    reports => {
        if (!reports) return false;

        return reports.some(report => report.isExceeded === true);
    }
);

export const isRestrictionsDownlanding = createSelector(
    fromProductRestrictions.isProductRestrictionsDowlanding,
    RestrictionsDowlanding => RestrictionsDowlanding
);

export const isPaymentDisabled = (locationNo: number, config: IConfig) => createSelector(
    getOrderErrorsMapped,
    fromCart.getCartTotalQuantity,
    fromOnlineOrder.isPaymentDisabledForOnlineOrderValidation(config),
    isPaymentDisabledForMember,
    fromPayment.isPaymentDisabledForPayments,
    fromCreditCards.isPaymentDisabledForCards,
    isZeroPaymentsDisabled,
    hasExceededProductsLimit(locationNo),
    (errors,
        cartTotalQuantity,
        orderPaymentDisabled,
        memberPaymentDisabled,
        paymentDisabledForPayments,
        cardsPaymentDisabled,
        zeroPaymentDisabled,
        exceededLimit
    ) => !cartTotalQuantity ||
    paymentDisabledForPayments ||
    orderPaymentDisabled ||
    exceededLimit ||
    (memberPaymentDisabled && (zeroPaymentDisabled ?? memberPaymentDisabled)) ||
    (cardsPaymentDisabled && (zeroPaymentDisabled ?? cardsPaymentDisabled)) ||
        errors
);

export const getGoogleAPIKey = createSelector(
    fromAppSettings.getLoyaltyAppSettings,
    (loyaltyAppSettings) => loyaltyAppSettings ? loyaltyAppSettings : null,
);
