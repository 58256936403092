import { Injectable, Component, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, RouteConfigLoadEnd } from '@angular/router';
//import { parseUrlPathInSegments } from '../classes/url-path-parser';

import * as Tokens from '@shared/core/tokens';

import { BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DynamicPathsGuard implements CanActivate {
    public routeUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _router: Router,

    ) { }

    private _navigateTo404(): Promise<boolean> {
        return this._router.navigateByUrl('/404');
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return new Promise<boolean>(resolve => {
            this.routeUpdate$
                .pipe(
                    filter(hasUpdated => hasUpdated !== null),
                    take(1),
                )
                .subscribe(hasUpdated => {
                    if (!hasUpdated) {

                        this._navigateTo404();

                        return resolve(false);
                    }
                    const splittedUrl: string[] = state.url.split('/').filter(segment => segment);

                    const isVenueMode = !!(this._config.venue && this._config.venue?.id && this._config.venue?.name);

                    if (isVenueMode && splittedUrl[0] === this._config.venue.name) {
                        this._router.navigateByUrl(state.url);
                    } else {
                        this._navigateTo404();
                    }

                    resolve(false);
                });
        });
    }
}
