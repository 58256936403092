import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map, combineLatest, switchMap, take, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CartController {
    public popupTimeout: number = 300;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
        private _routeService: Services.RouteService,
    ) { }

    public handleActiveOrderContinue(modalId: number, locationNo: number): void {
        this._store
            .pipe(
                select(selectors.isCheckoutPage),
                take(1)
            ).subscribe(isCheckoutPage => {
                this.activeOrderContinue(modalId);

                if (!isCheckoutPage) {
                    // this._routeService.navigateToLocation(locationNo);

                    /* Give some time to close modal before navigating to avoid modal's 'injury'. This is pure precautious */
                    setTimeout(() => this._routeService.navigateToCartsLocation(), 501);

                }
            });
    }

    public handleActiveOrderStartNew(modalId: number, locationNo: number): void {
        this._store
            .pipe(
                select(selectors.isCheckoutPage),
                take(1)
            ).subscribe(isCheckoutPage => {
                this.activeOrderStartNew(modalId, locationNo);
                if (isCheckoutPage) {
                    setTimeout(() => this._routeService.navigateToCartsLocation(), 501);
                }
            });
    }

    public getCartLocationNo$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getCart),
                map(cart => cart.locationNo)
            );
    }

    public updateCartPickupTime(pickupTime: OLO.Ordering.IPickupTime): void {
        this._store
            .pipe(
                select(selectors.getCart),
                take(1)
            ).subscribe(cart => {
                this._store.dispatch(actions.CartPickupTimeUpdate({ modalId: null, locationNo: cart.locationNo, pickupTime }));
            });
    }

    public cartMenuFlowTotalQuantityForCurrentLocation$(menuFlowId: number): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getCartMenuFlowTotalQuantityForCurrentLocation(menuFlowId))
            );
    }

    public cartSimpleProductTotalQuantityForCurrentLocation$(productId: number): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getCartSimpleProductTotalQuantityForCurrentLocation(productId))
            );
    }

    public cartTotalQuantity$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getCartTotalQuantity),
            );
    }

    public cartTotalValue$(): Observable<number> {
        return this._store
            .pipe(
                select(selectors.getCartTotalValue),
            );
    }

    public cartLocationFriendlyName$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getActiveLocationFriendlyNameFromCartOrCurrentLocation(this._config)),
            );
    }

    public isPopupVisible$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isCartPopupVisible)
            );
    }

    public popupAnimation$(): Observable<OLO.Animations.IN | OLO.Animations.OUT> {
        return this._store
            .pipe(
                select(selectors.cartPopupAnimation)
            );
    }

    public cartPickupTime$(): Observable<OLO.Ordering.IPickupTime> {
        return this._store
            .pipe(
                select(selectors.getCartPickupTime)
            );
    }

    public cartPickupTimeLabel$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getPickupTimeLabelForCart),
            );
    }

    public cartPickupTimeLabelFull$(prefix: string = 'PICK UP ', type: OLO.Types.PICKUP_LABEL_TYPES = 'common'): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getCartPickupTime),
                filter(pickupTime => !!pickupTime === true),
                combineLatest(
                    this._store
                        .pipe(
                            select(selectors.isCollectionTypeDineIn(this._config))
                        )
                ),
                map(([pickupTime, isDineIn]) => {
                    if (isDineIn) return 'Dine in order';

                    return `${prefix || ''}${Utils.Pickups.createPickupLabelName(
                        this._config.futureOrders,
                        type,
                        pickupTime?.Date || new Date(),
                        pickupTime ? pickupTime.IsAsap : true,
                        null,
                        pickupTime?.IsAsap ? pickupTime.MinutesFromNow : null
                    )}`;
                })
            );

    }

    public cartMenuFlowDescription$(id: number): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getCartMenuFlowDescription(id))
            );
    }

    public cartMenuFlowSpecialInstructions$(id: number): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getCartMenuFlowSpecialInstructions(id))
            );
    }

    public cartSimpleProductSpecialInstructions$(id: number): Observable<string> {
        return this._store
            .pipe(
                select(selectors.getCartSimpleProductSpecialInstructions(id))
            );
    }

    public getAllCartItemsSorted$(): Observable<Array<State.ICartMenuFlow | State.ICartSimpleItem>> {
        return this._store
            .pipe(
                select(selectors.getCartAllItems)
            );
    }

    public getAllCartItemsSize$(): Observable<number> {
        return this.getAllCartItemsSorted$()
            .pipe(
                map(arr => arr instanceof Array ? arr.length : 0)
            );
    }

    public cartItemsListIsOverLimit$(): Observable<boolean> {
        return this.getAllCartItemsSize$()
            .pipe(
                map(size => {
                    const limit = this._config.ui?.checkoutMaxCartItemsLines;

                    return typeof limit === 'number' ? size > limit : false;
                })
            );
    }

    public emptyBtnLabel$(): Observable<string> {
        return this._store
            .pipe(
                select(selectors.routeIsLocationDetailsPage(this._config)),
                map(isLocationDetails => isLocationDetails ? 'view menu' : 'Choose store')
            );
    }

    public calculateCartItemTotalValue(item: State.ICartMenuFlow & State.ICartSimpleItem): number {
        return item.MenuFlowId ? item.UnitTotalValue * item.Quantity : item.UnitPrice * item.Quantity;
    }

    public cartItemTotalValue$(item: State.ICartMenuFlow & State.ICartSimpleItem): Observable<number> {
        if (item.MenuFlowId) {
            return this._store
                .pipe(
                    select(selectors.getCartMenuFlowById(item._Id)),
                    filter(menuFlow => menuFlow !== undefined),
                    map(menuFlow => {
                        const extraPrice = menuFlow.Pages.reduce((prev, curr) => prev + curr.Products
                            .reduce((prevProd, currProd) =>
                                        currProd.IngredientsChanges?.IngredientsModified[0]?.ExtraPrice ?
                                            prevProd + currProd.IngredientsChanges.IngredientsModified[0].ExtraPrice :
                                            prevProd,
                            0), 0);

                        return menuFlow.Quantity * (menuFlow.UnitTotalValue + extraPrice);
                    })
                );
        }

        return this._store
            .pipe(
                select(selectors.getCartSimpleItemById(item._Id)),
                filter(product => product !== undefined),
                map(product => product.Quantity * product.UnitPrice)
            );
    }

    public cartItemTotalQuantity$(item: State.ICartMenuFlow & State.ICartSimpleItem): Observable<number> {
        if (item.MenuFlowId) {
            return this._store
                .pipe(
                    select(selectors.getCartMenuFlowById(item._Id)),
                    filter(menuFlow => menuFlow !== undefined),
                    map(menuFlow => menuFlow.Quantity)
                );
        }

        return this._store
            .pipe(
                select(selectors.getCartSimpleItemById(item._Id)),
                filter(product => product !== undefined),
                map(product => product.Quantity)
            );
    }

    public cartItemTitle(item: State.ICartMenuFlow & State.ICartSimpleItem): string {
        return item.IsUpsell ? item?.CustomerFriendlyDescription || item.DisplayName : item.PosDisplay;
    }

    public cartItemDescription(item: State.ICartMenuFlow & State.ICartSimpleItem): Observable<string> {
        return item.MenuFlowId ? this.cartMenuFlowDescription$(item._Id) : null;
    }

    public cartItemSpecialInstructions(item: State.ICartMenuFlow & State.ICartSimpleItem): Observable<string> {
        return item.MenuFlowId ?
            this.cartMenuFlowSpecialInstructions$(item._Id) :
            this.cartSimpleProductSpecialInstructions$(item._Id);
    }

    public showViewCart$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getDeviceType),
                combineLatest(
                    this.cartTotalQuantity$(),
                    this._store
                        .pipe(
                            select(selectors.isCheckoutPage)
                        )
                ),
                map(([device, total, isCheckoutPage]) => isCheckoutPage === false && device === OLO.Enums.DEVICE_TYPE.MOBILE && total > 0)
            );
    }

    /* MOVE */
    public decrementCartItem(item: State.ICartMenuFlow | State.ICartSimpleItem): void {
        const id: number = item._Id;
        const isMenuFlow: boolean = !!item.MenuFlowId;
        const shouldRemove: boolean = item.Quantity <= 1;

        if (isMenuFlow) {
            if (shouldRemove) {
                return this._store.dispatch(actions.CartMenuFlowRemove({ tempId: id }));
            }

            return this._store.dispatch(actions.CartMenuFlowDecrement(id));
        }

        if (shouldRemove) {
            return this._store.dispatch(actions.CartSimpleItemRemove({ tempItemId: id }));
        }

        return this._store.dispatch(actions.CartSimpleItemDecrement(id));
    }

    /* MOVE */
    public incrementCartItem(item: State.ICartMenuFlow | State.ICartSimpleItem): void {
        const id: number = item._Id;
        const isMenuFlow: boolean = !!item.MenuFlowId;

        if (isMenuFlow) {
            return this._store.dispatch(actions.CartMenuFlowIncrement(id));
        }

        return this._store.dispatch(actions.CartSimpleItemIncrement(id));
    }

    /* MOVE */
    public editCartItem(item: State.ICartMenuFlow | State.ICartSimpleItem): void {
        return this._store.dispatch(actions.CartEditItem({ item }));
    }

    /* MOVE */
    public activeOrderContinue(modalId: number): void {
        this._store.dispatch(actions.CartActiveOrderContinue({ modalId }));
    }

    /* MOVE */
    public activeOrderStartNew(modalId: number, locationNo: number): void {
        this._store.dispatch(actions.CartActiveOrderStartNew({ modalId, locationNo }));
    }

    // public clearCart(): void {
    //     this._store.dispatch(actions.CartReset());
    //     this._store.dispatch(actions.OnlineOrderStateReset());
    // }

    /* MOVE */
    public removeAllItems(): void {
        /* Will preserve info about online menu and location */
        this._store.dispatch(actions.CartReset());
    }

    /* MOVE */
    public quickAddToCart(item: State.ICartMenuFlow | State.ICartSimpleItem | APIv1.OnlineMenuProductResponseModel): void {
        this._store
            .pipe(
                select(selectors.getCurrentLocationNo),
                take(1)
            ).subscribe(locationNo => {
                const convertedItem = Utils.Items.convertToSanitizedSimpleItem(item, locationNo);
                this._store.dispatch(actions.CartSetup({ modalId: null, locationNo, item: convertedItem }));
            });
    }

    public addAsIs(item: APIv1.OnlineMenuProductResponseModel): void {
        this._store
            .pipe(
                select(selectors.getCurrentLocationNo),
                take(1)
            ).subscribe(locationNo => {
                this._store.dispatch(
                    actions.WizzardSilentSetupItem(locationNo, item)
                );
            });

    }
}
