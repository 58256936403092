/* https://www.npmjs.com/package/js-cookie */
import { Injectable } from '@angular/core';

import { CookieService } from './cookie.shared.service';
import { CryptoService } from './crypto.shared.service';

import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private _sessionTTLInDays: number = 30;

    constructor(
        public cookieService: CookieService,
        public cryptoService: CryptoService,
    ) { }

    public createSession(sessionKey: string, accountId: string | number, authorizationType: OLO.Enums.LOGIN_TYPE): void {
        this._saveSessionDataInUnsafeStorage(sessionKey, accountId, authorizationType);
        this._saveSecuredSessionKey(sessionKey, accountId, authorizationType);
    }

    public getSession(): Observable<OLO.Authorization.ISessionData> {
        const validateSessionData = this._compareStoredSessionKeys();
        if (!validateSessionData) return of(null);

        const sessionData = this._getSessionDataFromUnsafeStorage();
        return of(sessionData);
    }

    public removeSession(): void {
        this._removeSessionDataFromUnsafeStorage();
        this._removeSecureSessionKey();
    }

    public validateSessionData(onRefreshRequestStart: Function = null, onRefreshRequestFinish: Function = null): Observable<boolean> {
        const result = this._compareStoredSessionKeys();

        return of(result);
    }

    private _saveSecuredSessionKey(sessionKey: string, accountId: string | number, authorizationType: OLO.Enums.LOGIN_TYPE): void {
        const secureSessionKey = this._createSecureSessionKey(sessionKey, accountId, authorizationType);

        /* Create secure cookie */
        this.cookieService.set(OLO.Enums.COOKIE_STORAGE.SESSION as unknown as string, secureSessionKey, this._sessionTTLInDays);
    }

    private _getSecureSessionKey(): string {
        const secureSessionKey = this.cookieService.get(OLO.Enums.COOKIE_STORAGE.SESSION as unknown as string);
        return secureSessionKey ? secureSessionKey.toString() : null;
    }

    private _removeSecureSessionKey(): void {
        this.cookieService.remove(OLO.Enums.COOKIE_STORAGE.SESSION as unknown as string);
    }

    private _compareStoredSessionKeys(): boolean {
        const realSecureSessionkey: string | null = this._getSecureSessionKey();

        if (!realSecureSessionkey) return false;

        const unsafeSessionKey: string | null = Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.SESSION_KEY as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        const unsafeAccountId: string | null = Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.ACCOUNT_ID as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        const unsafeAuthorizationType: number | null = +Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.AUTHORIZATION_TYPE as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);

        if (!unsafeSessionKey || !unsafeAccountId || !unsafeAuthorizationType) { return false; }

        const recreateSecureSessionKey = this._createSecureSessionKey(unsafeSessionKey, unsafeAccountId, unsafeAuthorizationType);

        return recreateSecureSessionKey === realSecureSessionkey;
    }

    private _createSecureSessionKey(sessionKey: string, accountId: string | number, authorizationType: OLO.Enums.LOGIN_TYPE): string {
        const machineStampEncrypted: string = this.cryptoService.hashMachineStampKeyp();
        const sessionKeyEncrypted: string = this.cryptoService.hash(sessionKey);
        const accountIdEncrypted: string = this.cryptoService.hash(accountId.toString());
        const authorizationTypeEncrypted: string = this.cryptoService.hash(authorizationType.toString());
        const secureSessionKey: string = `${machineStampEncrypted}:${sessionKeyEncrypted}:${accountIdEncrypted}:${authorizationTypeEncrypted}`;

        return secureSessionKey;
    }

    private _saveSessionDataInUnsafeStorage(sessionKey: string, accountId: string | number, authorizationType: OLO.Enums.LOGIN_TYPE): void {
        Utils.Storage.set(OLO.Enums.SESSION_STORAGE.SESSION_KEY as unknown as string, sessionKey, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        Utils.Storage.set(OLO.Enums.SESSION_STORAGE.ACCOUNT_ID as unknown as string, accountId, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        Utils.Storage.set(OLO.Enums.SESSION_STORAGE.AUTHORIZATION_TYPE as unknown as string, authorizationType, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
    }

    private _removeSessionDataFromUnsafeStorage(): void {
        Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.SESSION_KEY as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.ACCOUNT_ID as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.AUTHORIZATION_TYPE as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
    }

    private _getSessionDataFromUnsafeStorage(): OLO.Authorization.ISessionData {
        const unsafeSessionKey: string | null = Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.SESSION_KEY as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        const unsafeAccountId: string | null = Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.ACCOUNT_ID as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);

        const authType: string = Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.AUTHORIZATION_TYPE as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string);
        // const unsafeAuthorizationType: OLO.Enums.LOGIN_TYPE | null = Enums.LOGIN_TYPE[Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.AUTHORIZATION_TYPE as unknown as string, OLO.Enums.SESSION_STORAGE.STORAGE_KEY as unknown as string)];
        let unsafeAuthorizationType: OLO.Enums.LOGIN_TYPE | null = null;
        switch (true) {
            case authType == OLO.Enums.LOGIN_TYPE.MEMBER_ID as unknown as string:
                unsafeAuthorizationType = OLO.Enums.LOGIN_TYPE.MEMBER_ID;
                break;

            case authType == OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN as unknown as string:
                unsafeAuthorizationType = OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN;
                break;

            case authType == OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN as unknown as string:
                unsafeAuthorizationType = OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN;
                break;

            case authType == OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN as unknown as string:
                unsafeAuthorizationType = OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN;
                break;
        }

        const sessionData: OLO.Authorization.ISessionData = {
            SessionKey: unsafeSessionKey,
            AccountId: unsafeAccountId,
            AuthorizationType: unsafeAuthorizationType
        };

        return sessionData;
    }
}
