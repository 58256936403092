export class LocationCollectionTypesChecker {
    private _pickupTypes: APIv1.TerminalLocationOrderTypeModel[] = [];
    private _dineInTypes: APIv1.TerminalLocationOrderTypeModel[] = [];
    private _deliveryTypes: APIv1.TerminalLocationOrderTypeModel[] = [];

    constructor(
        private _location: APIv1.OnlineOrderingLocationBusinessModel,
        private _config: IConfig,
    ) {
        if (!_location) return;
        this._initFiltering();
    }

    private _initFiltering(): void {
        this._filterPickupTypes();
        this._filterDineInTypes();
        this._filterDeliveryTypes();
    }

    private _filterPickupTypes(): void {
        const pickupConfig = this._config.collectionTypes?.pickup;
        if (pickupConfig?.enabled === true) {
            this._pickupTypes = this._location.OrderTypes
                .filter(orderType => {
                    return pickupConfig?.orderTypeIds.includes(orderType.Id);
                });
        }
    }

    private _filterDineInTypes(): void {
        const dineInConfig = this._config.collectionTypes?.dineIn;
        if (dineInConfig?.enabled) {
            const buzzerId = dineInConfig?.dineInBuzzer?.orderTypeId;
            const tableId = dineInConfig?.dineInTable?.orderTypeId;

            this._location.OrderTypes.forEach(orderType => {
                if (buzzerId === orderType.Id || tableId === orderType.Id) {
                    this._dineInTypes.push(orderType);
                }
            });
        }
    }

    private _filterDeliveryTypes(): void {
        const deliveryConfig = this._config.collectionTypes?.delivery;
        if (deliveryConfig?.enabled && deliveryConfig?.orderTypeId) {
            this._deliveryTypes = this._location.OrderTypes
                .filter(orderType => {
                    return orderType.Id === deliveryConfig.orderTypeId;
                });
        }
    }

    public getPickupIds(): number[] {
        return this._mapToId(
            this._pickupTypes
        );
    }

    public hasPickups(): boolean {
        return this.getPickupIds().length > 0;
    }

    public getDineInIds(): number[] {
        return this._mapToId(
            this._dineInTypes
        );
    }

    public hasDineIn(): boolean {
        return this.getDineInIds().length > 0;
    }

    public getDeliveryIds(): number[] {
        return this._mapToId(
            this._deliveryTypes
        );
    }

    public hasDelivery(): boolean {
        return this.getDeliveryIds().length > 0;
    }

    public hasAnyTypes(): boolean {
        return this.hasDelivery()
            || this.hasPickups()
            || this.hasDineIn();
    };

    private _mapToId(collectionArr: APIv1.TerminalLocationOrderTypeModel[]): number[] {
        return collectionArr.map(orderType => orderType.Id);
    }
}
