import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import { CollectionTypeParams } from '@shared/state/models';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { LoyaltyAppService } from './loyalty-app.shared.service';

import { Observable, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppSettingsService {
    private _internetConnectionListener$: Subscription;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public loyaltyAppService: LoyaltyAppService,
    ) { }

    public init(): void {
        this._internetConnectionListener$ = Utils.HTTP.isOnline$()
            .subscribe(online => this.store.dispatch(actions.AppSettingsSetOnlineStatus({ online })));

        this.loyaltyAppService.requestLoyaltyAppInfo();

        this._setupCurrentCollectionTypes();
        this._setVenueOrLocationMode(this.config.venue && this.config.venue.id && this.config.venue.name ? OLO.Enums.APP_MODE.VENUE : OLO.Enums.APP_MODE.LOCATION);
        this._setDeviceType(Utils.Devices.isMobile() ? OLO.Enums.DEVICE_TYPE.MOBILE : OLO.Enums.DEVICE_TYPE.DESKTOP);
    }

    private _setupCurrentCollectionTypes(): void {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        const venueMode = this.config.venue?.id && this.config.venue?.name || null;
        const isDeliveryConfigured = this.config.collectionTypes?.delivery?.enabled === true;

        if (venueMode/*  || !isDeliveryConfigured */) {
            this.store.dispatch(actions.SetCollectionType(new CollectionTypeParams(OLO.Enums.COLLECTION_TYPE.PICKUP)));
        } else {
            const urlCollection = urlParams.get('orderTypeId');
            const storageCollection = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.COLLECTION_TYPE);
            let storageParsed: CollectionTypeParams = null;
            if (storageCollection) {
                try {
                    storageParsed = JSON.parse(storageCollection);
                } catch (error) {
                    console.error('Unable to parse collection type values', storageCollection);
                }
            }
            if (storageParsed) {
                return this.store.dispatch(actions.SetCollectionType(storageParsed));
            }

            const isDefaultDelivery = this.config.collectionTypes?.delivery?.enabled === true && this.config.collectionTypes?.delivery?.isDefault === true;

            const deliveryOrderTypeId = this.config.collectionTypes?.delivery?.enabled !== true ? null : this.config.collectionTypes?.delivery?.orderTypeId || null;

            if (deliveryOrderTypeId !== null &&
                urlCollection && +urlCollection === deliveryOrderTypeId
                // || storageParsed && storageParsed.orderTypeId === deliveryOrderTypeId
                || isDefaultDelivery
            ) {
                this.store.dispatch(actions.SetCollectionType(new CollectionTypeParams(deliveryOrderTypeId)));
            } else {
                this.store.dispatch(actions.SetCollectionType(new CollectionTypeParams(OLO.Enums.COLLECTION_TYPE.PICKUP)));
            }
        }
    }

    private _setVenueOrLocationMode(mode: OLO.Enums.APP_MODE): void {
        this.store.dispatch(actions.SetApplicationMode({ mode }));
    }

    private _setDeviceType(deviceType: OLO.Enums.DEVICE_TYPE): void {
        this.store.dispatch(actions.SetDeviceType({ deviceType }));
    }

    public setInitAppInitFlag(): void {
        this.store.dispatch(actions.SetApplicationInitFlag());
    }
}
