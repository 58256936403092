export class DetailedOrderTotalsReceiptCalculator implements OLO.Ordering.IOrderSummary {
    public Subtotal: number = 0;
    public Tax: number = 0;
    public Total: number = 0;
    public Surcharges: APIv1.OrderSurchargeDefinition[] = [];

    constructor(
        private _order: APIv1.OnlineOrderDetailedBusinessModel
    ) {
        if (!_order) return;

        this._calculateValues();
    }

    private _calculateValues(): void {
        this.Surcharges = this._order.Surcharges;
        this.Total = this._order.TotalGrossValue;
        this.Subtotal = this._order.TotalNettValue;
        this.Tax = this._order.TotalTaxes.reduce((acc, tax) => {
            return acc += tax.Value;
        }, 0);
    }
}
