import { CreditCards } from '@shared/core/utils/credit-cards.utils';

export class CreditCard {
    public Id!: number;
    public IsDefault!: boolean;
    public SaveAwait!: boolean;
    public ValidationStatus!: OLO.Types.VALIDATION_STATUS;
    public number!: string;
    public PaymentProvider!: OLO.Enums.PAYMENT_PROVIDER;
    public NiceName!: string;
    public DisplayName!: string;
    public Token!: string;
    public CardType!: OLO.Enums.CREDIT_CARD_TYPES;
    public ExpirationDate!: string;
    public LocationNo!: number;
    public FatZebraToken!: APIv2.NullableFatZebraTokenVerification;
    public AdyenPaymentData!: Adyen.PaymentData;

    constructor(
        paymentProvider: OLO.Enums.PAYMENT_PROVIDER,
        number: string,
        type: OLO.Enums.CREDIT_CARD_TYPES,
        expiryDate: string,
        token: string,
        isDefault: boolean,
        saveCard: boolean,
        validationStatus: OLO.Types.VALIDATION_STATUS,
        locationNo: number,
        id: number,
        fatZebraToken: APIv2.NullableFatZebraTokenVerification,
        adyenPaymentData: Adyen.PaymentData
    ) {
        this.PaymentProvider = paymentProvider;
        this.ExpirationDate = CreditCards.dateToApiFormat(expiryDate);
        this.CardType = type;
        this.Token = token;
        this.Id = id;
        this.IsDefault = isDefault;
        this.SaveAwait = saveCard;
        this.ValidationStatus = validationStatus;
        this.LocationNo = locationNo;
        this.FatZebraToken = fatZebraToken;
        this.AdyenPaymentData = adyenPaymentData;

        if(number && number.substring) {
            this.DisplayName = this.NiceName = number.substring(number.length - 4);
        }
    }
}
