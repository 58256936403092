import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';
import { map, distinct, distinctUntilChanged, auditTime, filter, combineLatest, withLatestFrom, take, tap, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TransactionsController {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: IConfig,
        private _store: Store<State.IStateShared>,
    ) { }

    public getTransactionSummary$(transactionId: number): Observable<OLO.Ordering.IOrderSummary> {
        return this.getTransaction$(transactionId)
            .pipe(
                filter(transaction => transaction !== undefined && transaction !== null),
                map(transaction => ({
                    Subtotal: transaction.Total,
                    Tax: transaction.TaxTotal,
                    Total: transaction.TotalGross,
                }))
            );
    }

    public requestLatestTransactionsForCurrentMember(): void {
        this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.data !== null),
                switchMap(state => this._store
                    .pipe(
                        select(selectors.getLatestTransactionsForMember(state.data.MemberId)),
                        map(transactions => ({
                            transactions,
                            memberId: state.data.MemberId
                        }))
                    )),
                take(1)
            ).subscribe(({ transactions, memberId }) => {
                if (!transactions || transactions.isDownloading === false || transactions.hasSucceeded === true && transactions.data.length === 0) {
                    this._store.dispatch(actions.LatestTransactionsRequest({ memberId }));
                }
            });
    }

    public getLatestTransactionsForCurrentMember$(): Observable<APIv1.LoyaltyAppTransactionModel[]> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.data !== null),
                switchMap(state => this._store
                    .pipe(
                        select(selectors.getLatestTransactionsForMember(state.data.MemberId)),
                        map(transactions => transactions && transactions.data ? transactions.data : null)
                    ))
            );
    }

    public isLoading$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.isDownloadingAnyTransaction)
            );
    }

    public getTransaction$(transactionId: number): Observable<APIv3.LoyaltyAppTransactionModel> {
        return this._store
            .pipe(
                select(selectors.getTransactionById(transactionId)),
                map(transaction => {
                    if (!transaction) return transaction;

                    return {
                        ...transaction,
                        TransactionProducts: transaction.TransactionProducts.filter(obj => obj.PLU !== -99999)
                    };
                })
            );
    }

    public hasNoTransactionsHistory$(): Observable<boolean> {
        return this._store
            .pipe(
                select(selectors.getMemberState),
                filter(state => state.data !== null),
                switchMap(state => this._store
                    .pipe(
                        select(selectors.getLatestTransactionsForMember(state.data.MemberId)),
                        map(transactions => transactions ?
                            transactions.downloadedDate
                            && transactions.hasSucceeded
                            && (!transactions.data || transactions.data.length === 0)
                            : false)
                    ))
            );

    }

    public getTransactionTitle$(transactionId: number, locationNo: number, includeLocationName: boolean = true): Observable<string> {
        return this.getTransaction$(transactionId)
            .pipe(
                filter(transaction => transaction !== undefined && transaction !== null),
                switchMap(transaction => this._store
                    .pipe(
                        select(selectors.getLocationDetails(locationNo)),
                        filter(location => location !== null),
                        map(location => {
                            const locationName: string = includeLocationName ? `${location.LocationFriendlyName} - ` : '';

                            return `${locationName}${Utils.Dates.descriptionDate(transaction.Date)}`;
                        })
                    ))
            );
    }
}
