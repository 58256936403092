import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';

import { JWTService } from './jwt.shared.service';
import { SessionService } from './session.shared.service';

import { Observable, of, forkJoin } from 'rxjs';
import { withLatestFrom, map, take, catchError } from 'rxjs/operators';
import { IMemberModel } from '@shared/state/interface';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly _whiteList: string[] = [
        'email-confirm',
        'reset-password',
    ];

    public readonly apiBaseUrl: string = this.config.api.base;
    public readonly apiKey: string = this.config.api.key;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public router: Router,
        public jwtService: JWTService,
        public sessionService: SessionService,
    ) { }

    public isAuthorized$(): Observable<boolean> {
        return this.jwtService.getCurrentTokens()
            .pipe(
                withLatestFrom(
                    this.store
                        .pipe(
                            select(selectors.isMemberAuthorizedJWT)
                        )
                ),
                map(([jwt, stateAuthorized]) => {
                    const JWTFailed: boolean = !!jwt === false || (jwt && !!jwt.AccessToken === false || !!jwt.RefreshToken === false);

                    return JWTFailed === false && stateAuthorized === true;
                })
            );
    }

    public validateSession(): Observable<boolean> {
        return this.jwtService.getCurrentTokens()
            .pipe(
                take(1),
                catchError(ex => of(false)),
                map(isValid => !!isValid)
            );
    }

    public signIn(credentials: APIv3.LoginMemberRequest): Observable<{
        sessionKey: string;
        jwt: OLO.Authorization.IJWTokenObject;
    }> {
        const postModel: APIv3.LoginMemberRequest = { ...credentials };

        return this.httpClient.post<OLO.Authorization.IJWTokenObject>(`${this.apiBaseUrl}/auth/member/login`, postModel)
            .pipe(
                map(response => {
                    if (response) {
                        return {
                            jwt: response,
                            sessionKey: 'temp-session-key'
                        };
                    }

                    return null;
                })
            );
    }

    public signUp(model: IMemberModel, existingMember: IMemberModel, overwriteProps: IMemberModel = { IsMobileValidated: true }): Observable<IMemberModel> {
        const CopyModel = model ? { ...model } : {};
        let CopyExistingMember = existingMember ? { ...existingMember } : {};

        CopyModel.PartialMemberId = CopyModel.MemberId;
        CopyExistingMember.PartialMemberId = CopyExistingMember.MemberId;

        delete CopyModel.MemberId;
        delete CopyExistingMember.MemberId;

        const postModel: IMemberModel = {
            SexId: OLO.Enums.SEX.RATHER_NOT_ANSWER,
            ...CopyModel,
        };
        /* Create new user */
        if (!CopyExistingMember || (typeof CopyExistingMember === 'object' && Object.keys(CopyExistingMember).length === 0)) {
            return this.httpClient.post<IMemberModel>(`${this.apiBaseUrl}/auth/member/signup`, {
                ...postModel,
                IsOnlineRegistered: true,
                ...overwriteProps,
            });
        }

        //
        //  Safe merge new model with old model.
        //  Make sure new member model without values
        //  won't overwrite existing defined values
        //
        CopyExistingMember = Object.keys(CopyModel).reduce((acc, key) => {
            const newVal = CopyModel[key];
            const existingVal = CopyExistingMember[key];

            if ((newVal === null || newVal === undefined) && existingVal !== null && existingVal !== undefined) {
                return {
                    ...acc,
                    [key]: existingVal
                };
            }

            return acc;
        }, {
            ...CopyExistingMember,
            ...postModel,
        } as IMemberModel);

        if (CopyExistingMember.IsOnlineRegistered === false) {
            CopyExistingMember.IsEmailValidated = false;
        }

        CopyExistingMember.IsOnlineRegistered = true;

        CopyExistingMember = {
            ...CopyExistingMember,
            ...overwriteProps,
        };

        return this.httpClient.post<boolean>(`${this.apiBaseUrl}/auth/member/signup`, CopyExistingMember)
            .pipe(
                map(() => ({ ...CopyExistingMember, MemberId: CopyExistingMember.PartialMemberId }))
            );
    }

    public verifyPhoneNumber(PhoneNumber: string, LoyaltyAppId: number = null): Observable<boolean> {
        console.log(PhoneNumber, LoyaltyAppId);
        const postModel: APIv1.PhoneTemporaryCodeRequest = {
            LoyaltyAppId,
            PhoneNumber,
        };

        return this.httpClient.post<boolean>(`${this.apiBaseUrl}/auth/temporaryCode`, postModel);
    }

    public verifyPhoneNumberToken(PhoneNumber: string, Token: string): Observable<boolean> {
        const postModel: APIv1.PhoneTemporaryCodeValidationRequest = {
            Token,
            PhoneNumber,
        };

        return this.httpClient.put<boolean>(`${this.apiBaseUrl}/auth/temporaryCode`, postModel);
    }

    public async softSignOut(): Promise<boolean> {
        //
        //  Prevents member data in state, but sets proper flags to false, and removes session data
        //
        this.sessionService.removeSession();
        this.jwtService.clearTokens();

        this.store.dispatch(actions.HistoryOrdersReset());
        this.store.dispatch(actions.CreditCardTokenDataReset());
        this.store.dispatch(actions.MemberAuthorizationSetFlag({ flag: false }));

        return true;
    }

    public async signOut(redirect: boolean | string = '/', resetCart: boolean = false): Promise<boolean> {
        await this.softSignOut();

        if (resetCart) this.store.dispatch(actions.CartReset());

        this.store.dispatch(actions.MemberStateReset());
        this.store.dispatch(actions.MemberGuestModeSet({ flag: true }));

        Utils.Storage.remove(OLO.Enums.USER_STORAGE.BIRTHDAY_REWARDS);

        if (redirect) {
            if (redirect === true) return this.router.navigate(['/']);

            return this.router.navigate([redirect]);
        }

        return true;
    }

    public deleteMemberAccount(): Observable<APIv3.MembersClearMemberPersonalData.Responses.$200> {
        return this.httpClient
            .delete<APIv3.MembersClearMemberPersonalData.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/personalData`);
    }

    public signUpSimple(member: State.IMemberModel): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.apiBaseUrl}/auth/member/signup`, member);
    }

    public validateLogin(params: APIv3.ValidateMemberLoginRequest = {}): Observable<APIv3.ValidateMemberLoginResponse> {

        return this.httpClient.post<APIv3.AuthValidateMemberLogin.Responses.$200>(`${this.config.api.base}/auth/member/validateLogin`, params);
    }

    public validateEmailWithMemberCardNumber(email: string, memberCardNo: string): Observable<APIv3.ValidateMemberLoginResponse | false> {

        return forkJoin(
            this.validateLogin({
                Login: email,
                LoginType: OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN,
            }),
            this.validateLogin({
                Login: memberCardNo,
                LoginType: OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN,
            }),
        ).pipe(
            map(([emailResponse, memberCardResponse]) => {
                if(!emailResponse || !memberCardResponse) {
                    throw new Error('Unable to match logins - invalid payload');
                }
                const match = Object.keys(emailResponse).reduce((acc, key) => {
                    if(!acc) return acc;

                    return emailResponse[key] === memberCardResponse[key];
                }, true);
                if(!match) return false;

                return emailResponse;
            })
        );
    }
}
