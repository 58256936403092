import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import { IStateShared, IWizzard, IWizzardMenuFlow, IWizzardSimpleItem, IWizzardMenuFlowPage, IWizzardMenuFlowItem } from '../interface';

const state = createFeatureSelector<IStateShared, IWizzard>('wizzard');

export const getWizzard = createSelector(
    state,
    wizzard => wizzard
);

export const getWizzardMenuFlow = createSelector(
    state,
    wizzard => wizzard.itemsMenuFlow
);

export const getWizzardSimpleItem = createSelector(
    state,
    wizzard => wizzard.itemsSimple
);

export const getWizzardMenuFlowUnitPrice = createSelector(
    state,
    wizzard => wizzard.itemsMenuFlow ? wizzard.itemsMenuFlow.UnitPrice : null
);

export const getWizzardSimpleItemUnitPrice = createSelector(
    state,
    wizzard => wizzard.itemsSimple ? wizzard.itemsSimple.UnitPrice : null
);

export const getWizzardMenuFlowQuantity = createSelector(
    state,
    wizzard => wizzard.itemsMenuFlow ? wizzard.itemsMenuFlow.Quantity : null
);

export const getWizzardSimpleItemQuantity = createSelector(
    state,
    wizzard => wizzard.itemsSimple ? wizzard.itemsSimple.Quantity : null
);

export const getWizzardMenuFlowTotalValue = createSelector(
    state,
    wizzard => {
        const extraPrice = wizzard.itemsMenuFlow.Pages.reduce((prev, curr) => prev + curr.Products
            .reduce((prevProd, currProd) =>
                        currProd.IngredientsChanges?.IngredientsModified[0]?.ExtraPrice ?
                            prevProd + currProd.IngredientsChanges.IngredientsModified[0].ExtraPrice :
                            prevProd,
            0), 0);

        return wizzard.itemsMenuFlow ? wizzard.itemsMenuFlow.Quantity * (wizzard.itemsMenuFlow.UnitTotalValue + extraPrice) : null;
    }
);

export const getWizzardSimpleItemTotalValue = createSelector(
    state,
    wizzard => wizzard.itemsSimple ? wizzard.itemsSimple.Quantity * wizzard.itemsSimple.UnitPrice : null
);

export const getWizzardTotalSelectedProducts = createSelector(
    state,
    wizzard => {
        let total: number = 0;
        if (wizzard.itemsMenuFlow) {
            wizzard.itemsMenuFlow.Pages.forEach(page => {
                page.Products.forEach(product => {
                    total += product.Quantity;
                });
            });
        }

        return total;
    }
);

export const getWizzardMenuFlowPageItemsTotalQuantity = (pageIdentifier: number) => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow) return null;
        const page = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageIdentifier);
        if (!page) return null;

        return page.Products.reduce((acc, product) => acc + product.Quantity, 0);
    }
);

export const getWizzardMenuFlowProductTotalQuantity = (pageIdentifier: number, productId: number) => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow) return null;
        const page = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageIdentifier);
        if (!page) return null;

        return page.Products.reduce((acc, product) => {
            if (product.ProductId === productId) {
                return product.Quantity;
            }

            return acc;
        }, 0);
    }
);

export const getWizzardMenuFlowProduct = (pageIdentifier: number, productId: number) => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow) return null;
        const page = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageIdentifier);
        if (!page) return null;

        return page.Products.find(obj => obj.ProductId === productId) || null;
    }
);

export const getWizzardErrors = createSelector(
    state,
    wizzard => wizzard.errors
);

export const getWizzardPosDisplay = createSelector(
    state,
    wizzard => wizzard.itemsMenuFlow ? wizzard.itemsMenuFlow.PosDisplay : wizzard.itemsSimple ? wizzard.itemsSimple.PosDisplay : null
);

export const getWizzardPosDescription = createSelector(
    state,
    wizzard => wizzard.itemsMenuFlow ? wizzard.itemsMenuFlow.PosDescription : wizzard.itemsSimple ? wizzard.itemsSimple.PosDescription : null
);

export const getWizzardKilojoules = createSelector(
    state,
    wizzard => wizzard.itemsMenuFlow ? wizzard.itemsMenuFlow.Kilojoules : wizzard.itemsSimple ? wizzard.itemsSimple.Kilojoules : null
);

export const getWizzardMenuFlowPageProducts = (pageIdentifier: number) => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow || !wizzard.itemsMenuFlow.Pages) return null;

        const page: IWizzardMenuFlowPage = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageIdentifier);

        return page && page.Products ? page.Products : null;
    }
);

export const isWizzardPageQuantityValid = (pageDetails: APIv1.MenuFlowPage) => createSelector(
    getWizzardMenuFlowPageItemsTotalQuantity(pageDetails.PageIdentifier),
    count => {
        if (count === null) return null;

        return count >= pageDetails.PageMinQuantity && count <= pageDetails.PageMaxQuantity;
    }
);

export const isWizzardPageQuantityMaxedOut = (pageDetails: APIv1.MenuFlowPage) => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow || !wizzard.itemsMenuFlow.Pages) return null;
        const page = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageDetails.PageIdentifier);
        if (!page || !page.Products) return null;

        const totalProducts = page.Products.reduce((acc, product) => acc += product.Quantity, 0);

        return totalProducts === pageDetails.PageMaxQuantity;
    }
);


export const wizzardPageHasSelectedAnyProduct = (pageDetails: APIv1.MenuFlowPage) => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow || !wizzard.itemsMenuFlow.Pages) return null;
        const page = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageDetails.PageIdentifier);

        return page && page.Products ? page.Products.length > 0 : null;
    }
);

export const getWizzardMenuFlowAllProductsForPage = (pageIdentifier: number): MemoizedSelector<IStateShared, IWizzardMenuFlowItem[]> => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow || !wizzard.itemsMenuFlow.Pages) return null;
        const page = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageIdentifier);

        return page && page.Products && page.Products.length > 0 ? page.Products : null;
    }
);

export const getWizzardMenuFlowFirstProductForPage = (pageIdentifier: number): MemoizedSelector<IStateShared, IWizzardMenuFlowItem> => createSelector(
    getWizzardMenuFlowAllProductsForPage(pageIdentifier),
    products => products && products[0] || null
);

export const getWizzardSelectedModifierForMenuFlowProduct = (pageIdentifier: number, productId: number) => createSelector(
    state,
    wizzard => {
        if (!wizzard.itemsMenuFlow || !wizzard.itemsMenuFlow.Pages) return null;
        const page = wizzard.itemsMenuFlow.Pages.find(p => p.PageIdentifier === pageIdentifier);

        if (!page) return null;

        const product = page.Products.find(Product => Product.ProductId === productId);

        if (!product) return null;

        let ingredient = null;

        if (product.IngredientsChanges && (product.IngredientsChanges.IngredientsAdded || product.IngredientsChanges.IngredientsModified)) {

            if (product.IngredientsChanges.IngredientsAdded && product.IngredientsChanges.IngredientsAdded[0]) {
                ingredient = product.IngredientsChanges.IngredientsAdded[0];
            }

            if (product.IngredientsChanges.IngredientsModified && product.IngredientsChanges.IngredientsModified[0]) {
                ingredient = product.IngredientsChanges.IngredientsModified[0];
            }
        }

        return ingredient;
    }
);

export const wizzardRecalculatePricesObj = createSelector(
    state,
    wizzard => wizzard.recalculatePrices,
);
