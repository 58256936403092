import { CreditCard } from './credit-card.model';

export class CreditCardBuilder {
    public paymentProvider: OLO.Enums.PAYMENT_PROVIDER = null;
    public number: string = null;
    public type: OLO.Enums.CREDIT_CARD_TYPES = null;
    public expiryDate: string = null;
    public token: string = null;
    public isDefault: boolean = false;
    public saveCard: boolean = false;
    public id: number = null;
    public validationStatus: OLO.Types.VALIDATION_STATUS = null;
    public locationNo: number = null;
    public fatZebraToken: APIv2.NullableFatZebraTokenVerification = null;
    public adyenPaymentData: Adyen.PaymentData = null;

    public setPaymentProvider(paymentProvider: OLO.Enums.PAYMENT_PROVIDER) {
        this.paymentProvider = paymentProvider;

        return this;
    }


    public setNumber(number: string) {
        this.number = number;

        return this;
    }

    public setType(type: OLO.Enums.CREDIT_CARD_TYPES) {
        this.type = type;

        return this;
    }

    public setExpiryDate(expiryDate: string) {
        this.expiryDate = expiryDate;

        return this;
    }

    public setToken(token: string) {
        this.token = token;

        return this;
    }


    public setIsDefault(isDefault: boolean) {
        this.isDefault = isDefault;

        return this;
    }

    public setSaveCard(saveCard: boolean) {
        this.saveCard = saveCard;

        return this;
    }


    public setId(id: number) {
        this.id = id;

        return this;
    }

    public setValidationStatus(validationStatus: OLO.Types.VALIDATION_STATUS) {
        this.validationStatus = validationStatus;

        return this;
    }

    public setLocationNo(locationNo: number) {
        this.locationNo = locationNo;

        return this;
    }


    public setFatZebraToken(r: number, v: string) {
        this.fatZebraToken = {
            r,
            v
        };

        return this;
    }

    public setAdyenPaymentData(adyenPaymentData: Adyen.PaymentData) {
        this.adyenPaymentData = adyenPaymentData;

        return this;
    }

    public build(): CreditCard {
        return new CreditCard(
            this.paymentProvider,
            this.number,
            this.type,
            this.expiryDate,
            this.token,
            this.isDefault,
            this.saveCard,
            this.validationStatus,
            this.id,
            this.locationNo,
            this.fatZebraToken,
            this.adyenPaymentData,
        );
    }
}
