import { createAction, props } from '@ngrx/store';
import { IProductRestrictionsRequest } from './product-restrictions.interface';

export const ProductRestrictionsRequest = createAction(
    '[Product restrictions] Request',
    props<{ params: IProductRestrictionsRequest; }>(),
);

export const ProductRestrictionsSuccessRequest = createAction(
    '[Product restrictions] Success request',
    props<{ payload: APIv3.ProductRestriction[]; }>(),
);

export const ProductRestrictionsErrorRequest = createAction(
    '[Product restrictions] Error request'
);
