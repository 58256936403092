import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
        public router: Router,
    ) { }

    public getLocationsWithOnlineOrdering(customParams: APICommon.ITerminalsOnlineOrderingLocationsGetParams = {}): Observable<APIv1.TerminalsGetOnlineOrderingLocations.Responses.$200> {
        const defaultParams: APICommon.ITerminalsOnlineOrderingLocationsGetParams = {
            duration: APICommon.ORDERING_TIME_INFO_DURATION.DAY
        };

        const params: HttpParams = new HttpParams({
            fromObject: ({ ...defaultParams, ...customParams } as any)
        });

        return this.httpClient.get<APIv1.TerminalsGetOnlineOrderingLocations.Responses.$200>(`${this.config.api.base}/terminals/onlineOrderingLocations`, { params })
            .pipe(
                map(data => {
                    return data.map(location => {
                        return {
                            ...location,
                            OrderingTimeInfo: location.OrderingTimeInfo.map(timeInfo => {
                                return {
                                    ...timeInfo,
                                    PickupTimes: timeInfo.PickupTimes.map(pickup => {
                                        /* Fix data - when pickup has later 'To' or earlier 'From' values then get values from timeInfo object  */
                                        return {
                                            ...pickup,
                                            From: Utils.Dates.createHoursIntFromDate(pickup.From) < Utils.Dates.createHoursIntFromDate(timeInfo.OpeningTime) ? timeInfo.OpeningTime : pickup.From,
                                            To: Utils.Dates.createHoursIntFromDate(pickup.To) > Utils.Dates.createHoursIntFromDate(timeInfo.ClosingTime) ? timeInfo.ClosingTime : pickup.To,
                                        };
                                    })
                                };
                            })
                        };
                    });
                })
            );
    }

    // public getLocationPickupTimes(p: APICommon.ITerminalsLocationPickupTimeGetParams): Observable<APIv1.TerminalsGetMinimumPickupTimes.Responses.$200> {
    //     const params: HttpParams = new HttpParams({
    //         fromObject: ({
    //             ...p
    //         } as any)
    //     });

    //     return this.httpClient.get<APIv1.TerminalsGetMinimumPickupTimes.Responses.$200>(`${this.config.api.base}/terminals/locations/${p.locationNo}/pickupTimes`, { params });
    // }

    // public getOrderingTimeInfoForLocations(locationNos: number[], dateToCheck: string): Observable<APIv1.LocationOrderingTimeInfoBusinessModel[]> {
    //     /* This endpoint require Z in dateToCheck */
    //     return this.httpClient
    //         // tslint:disable-next-line:max-line-length
    //         .get<APIv1.TerminalsCompleteOrderingTimeInfo.Responses.$200>(`${this.config.api.base}/terminals/locations/orderingTimeInfo?${Utils.HTTP.idsToStringParams('locationNos', locationNos)}${dateToCheck ? `&dateToCheck=${dateToCheck.includes('Z') ? dateToCheck : dateToCheck + 'Z'}` : ''}`)
    //         .pipe(
    //             map(response => {


    //                 return response.map((orderingTime) => {
    //                     return {
    //                         ...orderingTime,

    //                         OrderingTimeInfo: orderingTime.OrderingTimeInfo.map((obj, index) => {
    //                             const dateObj: Date = Utils.Dates.createDate(dateToCheck);
    //                             dateObj.setDate(dateObj.getDate() + index);

    //                             const currDay = dateObj.getDay();
    //                             const localISODate = Utils.Dates.getLocalISOFormatDate(dateObj).replace(/T.+/i, 'T' + obj.OpeningTime + '.000Z');

    //                             return {
    //                                 ...obj,
    //                                 _Id: index + 1,
    //                                 _Date: localISODate,
    //                                 _DayName: Utils.Dates.getDayName(currDay)
    //                             };
    //                         })
    //                     };

    //                 });


    //                 // return response;
    //             })
    //         );
    // }

    public requestLocations(): void {
        this.store.dispatch(actions.LocationsRequest({
            venueNo: this.config.venue ? this.config.venue.id : null,
            dateToCheck: Utils.Dates.getLocalISOFormatDate(new Date(), false), /* Without Z - will not return TODAY if true */
            duration: APICommon.ORDERING_TIME_INFO_DURATION.MONTH,
        }));
    }
}
