import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';
import * as Models from '@shared/core/models';

import { ModalsService } from './modals.shared.service';
import { LocationsService } from './locations.shared.service';

import { Observable, of } from 'rxjs';
import { map, filter, withLatestFrom, combineLatest, switchMap, distinct, auditTime, distinctUntilChanged, tap, take, skipWhile } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PickupsService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>,
        public locationsService: LocationsService,
        public modalsService: ModalsService,
        public actions$: Actions,
        public router: Router,
    ) { }

    public availablePickupsListForLocation$(locationNo: number, limit: number = null, futureOrders: boolean = false) {
        return this.store
            .pipe(
                select(selectors.getAvailablePickupTimesWithFutureForLocation(this.config, locationNo, futureOrders)),
                map(arr => {
                    const list = [...arr];
                    if (!list) return null;

                    if (limit && list.length > limit) {
                        list.length = limit;
                    }

                    if (futureOrders && arr.length > limit) {
                        list.push(
                            new Models.PickupModels().generateDefaultSchedule()
                        );
                    }

                    if (!futureOrders) {
                        return list.map(obj => {
                            if (obj.IsAsap) {
                                return {
                                    ...obj,
                                    Name: 'ASAP'
                                };
                            }

                            return obj;
                        });
                    }

                    return list;

                }),
                auditTime(0),
            );
    }

    public selectedCurrentPickupTime$(): Observable<OLO.Ordering.IPickupTime> {
        return this.store.pipe(
            select(selectors.getCurrentPickupTime),
        );
    }

    public selectedCurrentPickupTimeLabel$(isSchedule: boolean, type: OLO.Types.PICKUP_LABEL_TYPES = 'location'): Observable<string> {
        return this.store.pipe(
            select(selectors.getCurrentPickupTime),
            map(pickupTime => {
                if (!isSchedule && pickupTime.IsAsap) {
                    return 'ASAP';
                }

                return Utils.Pickups.createPickupLabelName(
                    this.config.futureOrders,
                    type,
                    pickupTime.Date,
                    pickupTime.IsAsap,
                    null,
                    pickupTime.IsAsap ? pickupTime.MinutesFromNow : null);
            })
        );
    }

    public selectPickupTimeForCurrentLocation(pickupTime: OLO.Ordering.IPickupTime): void {
        this.store.dispatch(actions.CurrentLocationPickupTimeSet(pickupTime));
        this.store.dispatch(actions.LocationsFiltersSyncPickupTime(pickupTime.IsAsap ? null : pickupTime));
    }

    public validateSelectedPickupTimeObjForOnlineMenu(
        date: Date = new Date(),
        pickupTimeObj: OLO.Ordering.IPickupTime,
        onlineMenuTimes: { StartTime?: string; EndTime?: string; },
        orderingTimeInfo: APIv1.LocationOrderingTimeInfoModel[] = null,
        locationUpdatedPickupTimeObj: APIv1.MinimumPickupTimeModel = null,
    ): boolean {
        if (!pickupTimeObj
            || onlineMenuTimes === null
            || onlineMenuTimes.StartTime === null
            || onlineMenuTimes.EndTime === null
        ) return false;
        const d: Date = date;
        pickupTimeObj.Date = new Date(pickupTimeObj.Date);
        let isToday: boolean = pickupTimeObj.Date &&
            (pickupTimeObj.Date.getDate() === d.getDate() && pickupTimeObj.Date.getMonth() === d.getMonth() && pickupTimeObj.Date.getFullYear() === d.getFullYear());
        let hasExceededPlaceOrderTimeoutForPickupTime: boolean = pickupTimeObj.IsAsap ? false : d > pickupTimeObj.PlaceOrderTimeout;
        let isPickupTimeInOnlineMenuTimeRange: boolean = Utils.Dates.isHourInTimeRange(pickupTimeObj.Hour, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);
        let openHours: APIv1.OpeningHoursModel = orderingTimeInfo.find(obj => obj.Date.split('T')[0] === pickupTimeObj.DateLocalISO.split('T')[0]);
        const isPickupTimeInLocationOpenRange: boolean = !openHours ? true : Utils.Dates.isHourInTimeRange(pickupTimeObj.Hour, openHours.OpeningTime, openHours.ClosingTime);

        /* FUTURE ORDERS ONLY! */
        if (this.config.futureOrders && !pickupTimeObj.IsToday) {
            if (!isPickupTimeInOnlineMenuTimeRange || !isPickupTimeInLocationOpenRange) return false;

            return true;
        }

        if (locationUpdatedPickupTimeObj) {
            const locationClosingHour: number = Utils.Dates.createHoursIntFromDate(openHours.ClosingTime);
            const newPickupTime: Date = new Date(new Date().getTime() + locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000);
            const newPickupTimeHour: number = Utils.Dates.createHoursIntFromDate(newPickupTime);

            if (pickupTimeObj.IsAsap) {
                if (newPickupTimeHour >= locationClosingHour) {
                    return false;
                }
            } else {
                const targetPickupTimeDiff = pickupTimeObj.Id - locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000;
                const prepTimeExceedsPickupTime = targetPickupTimeDiff < d.getTime();
                if (prepTimeExceedsPickupTime) return false;
            }
        }

        /* AOLO-277 - when user spend too much time on checkout and menu changes */
        const now: Date = new Date();
        const nowHours: string = Utils.Dates.hoursFromDate(now, false) + ':59';
        const isNowInOnlineMenuRange: boolean = Utils.Dates.isHourInTimeRange(nowHours, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);

        const isOpenNow: boolean = openHours ?
            Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(now), openHours.OpeningTime, openHours.ClosingTime, 'from') :
            false;
        const willBeOpenToday: boolean = openHours ?
            Utils.Dates.isHourInHoursRange(Utils.Dates.getLocalISOFormatDate(now), Utils.Dates.getLocalISOFormatDate(now), openHours.ClosingTime, 'from') :
            false;
        const allowClosedLocationOrders = this.config.onlineOrders.allowClosedLocationOrders === true;

        /* Base mandatory checks no need to go deeper if any of these four are false */
        if (!isToday) return false;
        if (!isPickupTimeInOnlineMenuTimeRange) return false;
        if (allowClosedLocationOrders) {
            if (isOpenNow && pickupTimeObj.IsAsap === true && !isNowInOnlineMenuRange) return false;
            if (!isOpenNow && willBeOpenToday && pickupTimeObj.IsAsap === true && !isPickupTimeInOnlineMenuTimeRange) return false;
        } else {
            console.warn('Ordering from closed location is not allowed');
            if (pickupTimeObj.IsAsap === true && !isNowInOnlineMenuRange) return false;
        }

        let isUpdatedPickupTimeInLocationOpenHoursRange: boolean = true;
        let onlineMenuHasChanged: boolean = false;

        if (locationUpdatedPickupTimeObj && openHours) {
            //
            //  We don't want to take orders for user that will show up after location is closed.
            //  Check if pickupTime + target pickup time won't exceed location's opening time.
            //
            const newPickupTime: number = new Date().getTime() + locationUpdatedPickupTimeObj.MinimumPickupTime * 60 * 1000;
            const newPickupTimeDate: Date = new Date(newPickupTime);
            const newPickupTimeHour: string = Utils.Dates.hoursFromDate(newPickupTimeDate, false);

            isUpdatedPickupTimeInLocationOpenHoursRange = Utils.Dates.isHourInTimeRange(newPickupTimeHour, openHours.OpeningTime, openHours.ClosingTime);

            /* online menu has changed */
            const fromNowDateHours: string = Utils.Dates.hoursFromDate(new Date(), false);
            onlineMenuHasChanged = Utils.Dates.isHourInTimeRange(fromNowDateHours, onlineMenuTimes.StartTime, onlineMenuTimes.EndTime);
        }

        //
        //  Validate case when placeOrderTimeout has been exceeded but all params are ok
        //  Used for PAYMENT CASE, final step, where order can still be placed if location is open,
        //  newer pickup time is location open time range and online menu hasn't changed (TODO)
        //
        if (hasExceededPlaceOrderTimeoutForPickupTime && openHours && locationUpdatedPickupTimeObj) {
            return isPickupTimeInLocationOpenRange && isUpdatedPickupTimeInLocationOpenHoursRange && !onlineMenuHasChanged;
        }

        /* Regular check without updated data - used when navigating between pages */
        return !hasExceededPlaceOrderTimeoutForPickupTime && isPickupTimeInLocationOpenRange;
    }

    public validateCartWithPopup(): Observable<boolean | null> {
        return this.store.select(selectors.getCart)
            .pipe(
                take(1),
                tap(cart => {
                    if (cart.pickupTime.IsAsap) {
                        const pickupTimeObj = Utils.Pickups.overrdrivePickupTimeObjToAsap(cart.pickupTime);
                        this.store.dispatch(actions.CartSetPickupTime(pickupTimeObj));
                        this.store.dispatch(actions.OnlineOrderRecalculateRequest());
                    }
                }),
                switchMap(cart => this.store
                    .pipe(
                        select(selectors.getOnlineOrderState),
                        auditTime(10),
                        filter(onlineOrderState => onlineOrderState.recalculateRequest.isRecalculating === false),
                        take(1),
                        switchMap(onlineOrderState => {
                            if (onlineOrderState.recalculateRequest.hasFailed || onlineOrderState.recalculateRequest.data === null) {
                                return of(false);
                            }

                            return this.store
                                .pipe(
                                    select(selectors.getOrderingTimeInfoByLocationNo(cart.locationNo)),
                                    filter(orderingTimeInfo => orderingTimeInfo !== null && orderingTimeInfo !== undefined),
                                    take(1),
                                    withLatestFrom(
                                        this.store.select(selectors.getOrderingTimeInfoForCartPickupLocationNo(cart.locationNo)),
                                        this.store.select(selectors.isLocationOpenNowByOrderingTimeInfoObj(cart.locationNo)),
                                        this.store.select(selectors.getMinimumPickupTimeForLocationAndDate(cart.locationNo, cart.pickupTime.Date)),
                                        this.store.select(selectors.isCollectionTypeDineIn(this.config))
                                    ),
                                    map(([orderintTimeInfo, timeInfoObj, isOpenNow, preperationTimeObj, isDineIn]) => {
                                        if (!orderintTimeInfo) {
                                            return null;
                                        }
                                        const closedLocationOrder = this.config.onlineOrders.allowClosedLocationOrders === true || this.config.futureOrders ? true : isOpenNow;

                                        if (!orderintTimeInfo || orderintTimeInfo.length === 0 || !timeInfoObj || !preperationTimeObj || !closedLocationOrder) return false;

                                        if (isDineIn) {
                                            return true;
                                        }

                                        return this.validateSelectedPickupTimeObjForOnlineMenu(
                                            new Date(),
                                            cart.pickupTime,
                                            cart.onlineMenu,
                                            orderintTimeInfo,
                                            preperationTimeObj
                                        );
                                    }),
                                    tap(isCartValid => {
                                        if (isCartValid === false) {
                                            this.store.dispatch(actions.CartReset());

                                            /* AOLO-308 - Reset stuff, make sure data is reloaded */
                                            this.store.dispatch(actions.LocationsFiltersReset());
                                            this.store.dispatch(actions.CurrentLocationReset());

                                            const id: number = new Date().getTime();
                                            this.modalsService.show({
                                                id,
                                                type: 'alert',
                                                params: {
                                                    preTitle: 'WARNING',
                                                    title: 'Cart is empty',
                                                    body: `We've detected you spent
                                                    too much time placing an order.
                                                    Either our menu has changed or we
                                                    won't be able to prepare your
                                                    meal for selected pickup time.`
                                                }
                                            });

                                            this.actions$
                                                .pipe(
                                                    ofType(
                                                        actions.ModalClose
                                                    ),
                                                    map(action => action),
                                                    skipWhile(action => action.id !== id),
                                                    take(1)
                                                ).subscribe(action => {
                                                    this.router.navigate(['/'])
                                                        .then(() => {
                                                            this.locationsService.requestLocations();
                                                        });
                                                });

                                            return;
                                        }

                                        if (isCartValid === null) {
                                            this.modalsService.show({
                                                type: 'alert',
                                                params: {
                                                    preTitle: 'WARNING',
                                                    title: 'Error',
                                                    body: `There was an error
                                                    when validating your order.
                                                    Please try again.`
                                                }
                                            });
                                        }
                                    }),
                                    take(1)
                                );
                        })
                    )),
            );
    }
}
