import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import * as State from '@shared/state/interface';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MenuFlowsService {

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
        public store: Store<State.IStateShared>,
    ) { }

    public getMenuFlowDetailsForLocation(menuFlowId: number, locationNo: number): Observable<APIv1.MenuFlowsGetMenuFlowDetails.Responses.$200> {
        return this.httpClient.get<APIv1.MenuFlowsGetMenuFlowDetails.Responses.$200>(`${this.config.api.base}/menuFlows/${menuFlowId}/details/${locationNo}`)
            .pipe(
                map(menuFlow => {
                    menuFlow.Pages = menuFlow.Pages.reduce((acc, Page) => {
                        if (Page.PageMode !== 0) return acc;

                        Page.Products = Page.Products.map(product => {
                            if (product.BackgroundColor && typeof product.BackgroundColor === 'string') {
                                const match = product.BackgroundColor.match(/^\#[a-f0-9]{8}$/i);
                                if (match) {
                                    return {
                                        ...product,
                                        BackgroundColor: Utils.Colors.hexToRGBA(product.BackgroundColor),
                                    };
                                }

                                return product;

                            }
                            return product;
                        });
                        Page.Products = Page.Products.sort((a, b) => a.DisplayIndex - b.DisplayIndex);

                        return [
                            ...acc,
                            Page,
                        ];
                    }, [] as APIv1.MenuFlowPage[]);

                    return menuFlow;
                })
            );
    }

    public getFlatProductsFromMenuFlow(menuFlow: APIv1.MenuFlowDetailsModel): APIv1.MenuFlowProduct[] {
        return menuFlow.Pages.reduce((prevPage, nextPage) => prevPage.concat(nextPage.Products), []);
    }

    public getDefaultActivation(menuFlowId: number): Observable<APIv1.MenuFlowsGetMenuFlowDefaultActivation.Responses.$200> {
        return this.httpClient.get<APIv1.MenuFlowDefaultActivation>(`${this.config.api.base}/menuFlows/${menuFlowId}/defaultActivation`);
    }
}
