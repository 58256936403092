import { IAlertMessage } from './alertMessages/alert-messages.interface';
import { IAppSettings } from './appSettings/app-settings.interface';
import { IAvailablePickup } from './availablePickups/available-pickups.interface';
import { ICart } from './cart/cart.interface';
import { ICartPopup } from './cartPopup/cart-popup.interface';
import { ICollectionType } from './collectionType/collection-type.interface';
import { ICreditCards } from './creditCards/credit-cards.interface';
import { ICurrentLocation } from './currentLocation/current-location.interface';
import { IDietaryTagImage } from './dietaryTagsImages/dietary-tags-images.interface';
import { IFreeProductImage } from './freeProductsImages/free-products-images.interface';
import { IGeolocation } from './geolocation/geolocation.interface';
import { IGoogle } from './google/google.interface';
import { IHistoryOrderState } from './historyOrders/history-orders.interface';
import { IIngredient } from './ingredients/ingredients.interface';
import { ILatestTransaction } from './latestTransactions/latest-transactions.interface';
import { ILoader } from './loader/loader.interface';
import { ILocations } from './locations/locations.interface';
import { ILocationsFilters } from './locationsFilters/locations-filters.interface';
import { ILocationsImages } from './locationsImages/locations-images.interface';
import { ILoyaltyMessages } from './loyaltyMessages/loyalty-messages.interface';
import { ILoyaltyIntroductionPages } from './loyaltyIntroductionPages/loyalty-introduction-pages.interface';
import { ILoyaltyIntroductionPageImage } from './loyaltyIntroductionPagesImages/loyalty-introduction-pages-images.interface';
import { ILoyaltyProductProgramItem } from './loyaltyProductPrograms/loyalty-product-programs.interface';
import { ILoyaltyProgram } from './loyaltyPrograms/loyalty-programs.interface';
import { IMemberCardBarcodeImage } from './memberCardBarcodesImages/member-card-barcodes-images.interface';
import { IMembers } from './members/members.interface';
import { IMenuFlowDefaultActivation } from './menuFlowDefaultActivations/menu-flow-default-activations.interface';
import { IMenuFlowImage } from './menuFlowImages/menu-flow-images.interface';
import { IMenuFlows } from './menuFlows/menu-flows.interface';
import { IModal } from './modals/modal.interface';
import { IOnlineMenu } from './onlineMenu/online-menu.interface';
import { IOnlineMenuPagesImages } from './onlineMenuPagesImages/online-menu-pages-images.interface';
import { IOnlineMenuPageProductsImage } from './onlineMenuProductsImages/online-menu-products-images.interface';
import { IOnlineMenuFilters } from './onlineMenuFilters/online-menu-filters.interface';
import { IOnlineOrder } from './onlineOrder/online-order.interface';
import { IOrderType } from './orderTypes/order-types.interface';
import { IOrderTypeImage } from './orderTypesImages/order-types-images.interface';
import { IPayment } from './payment/payment.interface';
import { IProductImage } from './productImages/product-images.interface';
import { IProductRestrictions } from './productRestrictions/product-restrictions.interface';
import { IReorder } from './reorder/reorder.interface';
import { IRouter } from './router/router.interface';
import { ITopBar } from './topBar/top-bar.interface';
import { IVenuesImages } from './venuesImages/venues-images.interface';
import { IWizzard } from './wizzard/wizzard.interface';

export * from './alertMessages/alert-messages.interface';
export * from './appSettings/app-settings.interface';
export * from './availablePickups/available-pickups.interface';
export * from './cart/cart.interface';
export * from './cartPopup/cart-popup.interface';
export * from './collectionType/collection-type.interface';
export * from './creditCards/credit-cards.interface';
export * from './currentLocation/current-location.interface';
export * from './dietaryTagsImages/dietary-tags-images.interface';
export * from './freeProductsImages/free-products-images.interface';
export * from './geolocation/geolocation.interface';
export * from './google/google.interface';
export * from './historyOrders/history-orders.interface';
export * from './ingredients/ingredients.interface';
export * from './latestTransactions/latest-transactions.interface';
export * from './loader/loader.interface';
export * from './locations/locations.interface';
export * from './locationsFilters/locations-filters.interface';
export * from './locationsImages/locations-images.interface';
export * from './loyaltyIntroductionPages/loyalty-introduction-pages.interface';
export * from './loyaltyIntroductionPagesImages/loyalty-introduction-pages-images.interface';
export * from './loyaltyMessages/loyalty-messages.interface';
export * from './loyaltyProductPrograms/loyalty-product-programs.interface';
export * from './loyaltyPrograms/loyalty-programs.interface';
export * from './memberCardBarcodesImages/member-card-barcodes-images.interface';
export * from './members/members.interface';
export * from './menuFlowDefaultActivations/menu-flow-default-activations.interface';
export * from './menuFlowImages/menu-flow-images.interface';
export * from './menuFlows/menu-flows.interface';
export * from './modals/modal.interface';
export * from './onlineMenu/online-menu.interface';
export * from './onlineMenuFilters/online-menu-filters.interface';
export * from './onlineMenuPagesImages/online-menu-pages-images.interface';
export * from './onlineMenuProductsImages/online-menu-products-images.interface';
export * from './onlineOrder/online-order.interface';
export * from './orderTypes/order-types.interface';
export * from './orderTypesImages/order-types-images.interface';
export * from './payment/payment.interface';
export * from './productImages/product-images.interface';
export * from './productRestrictions/product-restrictions.interface';
export * from './reorder/reorder.interface';
export * from './router/router.interface';
export * from './topBar/top-bar.interface';
export * from './venuesImages/venues-images.interface';
export * from './wizzard/wizzard.interface';

export interface IStateShared {
    alertMessages?: IAlertMessage[];
    appSettings?: IAppSettings;
    availablePickups?: IAvailablePickup[];
    cart?: ICart;
    cartPopup?: ICartPopup;
    collectionType?: ICollectionType;
    creditCards?: ICreditCards;
    currentLocation?: ICurrentLocation;
    dietaryTagsImages?: IDietaryTagImage[];
    freeProductsImages?: IFreeProductImage[];
    geolocation?: IGeolocation;
    google?: IGoogle;
    historyOrders?: IHistoryOrderState;
    ingredients?: IIngredient[];
    latestTransactions?: ILatestTransaction[];
    loader?: ILoader;
    locations?: ILocations;
    locationsFilters?: ILocationsFilters;
    locationsImages?: ILocationsImages;
    loyaltyIntroductionPages?: ILoyaltyIntroductionPages;
    loyaltyIntroductionPagesImages?: ILoyaltyIntroductionPageImage[];
    loyaltyMessages?: ILoyaltyMessages;
    loyaltyProductPrograms?: ILoyaltyProductProgramItem;
    loyaltyPrograms?: ILoyaltyProgram;
    memberCardBarcodesImages?: IMemberCardBarcodeImage[];
    members?: IMembers;
    menuFlowDefaultActivations?: IMenuFlowDefaultActivation;
    menuFlowImages?: IMenuFlowImage[];
    menuFlows?: IMenuFlows[];
    modals?: IModal[];
    onlineMenu?: IOnlineMenu;
    onlineMenuPagesImages?: IOnlineMenuPagesImages;
    onlineMenuProductsImages?: IOnlineMenuPageProductsImage[];
    onlineMenuFilters?: IOnlineMenuFilters;
    onlineOrder?: IOnlineOrder;
    orderTypes?: IOrderType[];
    orderTypesImages?: IOrderTypeImage[];
    payment?: IPayment;
    productImages?: IProductImage[];
    productRestrictions?: IProductRestrictions;
    reorder?: IReorder[];
    router?: IRouter;
    topBar?: ITopBar;
    venuesImages?: IVenuesImages;
    wizzard?: IWizzard;
}
