import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import * as State from '@shared/state/interface';
import * as Tokens from '@shared/core/tokens';

import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    public currentPosition: Coordinates | boolean;

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public store: Store<State.IStateShared>
    ) { }

    public getUserCoords(): Promise<Coordinates> {
        return new Promise((resolve, reject) => {

            if (this.currentPosition) {
                resolve(this.currentPosition as Coordinates);
            }

            if (!navigator.geolocation || this.currentPosition === false) {
                this.currentPosition = false;
                reject(`GeolocationError`);
            }

            navigator.geolocation.getCurrentPosition(
                ({ coords }) => {
                    this.currentPosition = {
                        latitude: coords.latitude,
                        longitude: coords.longitude,
                        altitude: coords.altitude,
                        accuracy: coords.accuracy,
                        altitudeAccuracy: coords.altitudeAccuracy,
                        heading: coords.heading,
                        speed: coords.speed
                    };

                    resolve(this.currentPosition);

                },
                (error) => {
                    this.currentPosition = false;
                    reject(error);
                });
        });
    }

    public requestUserCoordinates(): void {
        this.store.pipe(
            select(
                selectors.getGeolocationState
            ),
            take(1)
        ).subscribe(state => {
            if (state.isRequesting || state.hasFailed || state.hasSucceeded) return;

            this.store.dispatch(actions.geolocationPositionRequest());
        });
    }
}
