import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, of } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FatZebraPaymentProviderService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: IConfig,
        public httpClient: HttpClient,
    ) { }

    public requestCardToken(locationNo: number = null, defaultSettings: APIv3.FatZebraSettingsResponse = null): Observable<APIv2.FatZebraSettingsResponse> {
        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for FatZebra payment provider');

            return of(defaultSettings)
                .pipe(
                    take(1),
                    map(response => ({
                        ...response,
                        Verification: response.Verification.toLocaleLowerCase(),
                    }))
                );
        }

        return this._getSettingsForLocation(locationNo);
    }

    protected _getSettingsForLocation(locationNo: number): Observable<APIv2.FatZebraSettingsResponse> {
        return this.httpClient.get<APIv3.FatZebraSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/fatZebra/settings/${locationNo}`)
            .pipe(
                map(response => ({
                    ...response,
                    Verification: response.Verification.toLocaleLowerCase(),
                })),
                catchError(ex => {
                    console.error('LocationNo not provided', ex);

                    return throwError(ex);
                })
            );
    }
}
